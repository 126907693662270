import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { Container, Row, Col } from "react-bootstrap";

export const TwoColumnContent = ({ slice }) => {
  if (!slice || !slice.primary) {
    console.log("Slice or primary missing");
    return null;
  }

  return (
    <section className="two-column-content bg-c-7 py-5 py-xl-8">
      <Container>
        <Row>
          {/* Left Column */}
          <Col xs={12} md={6}>
            <div className="content-box mb-4">
              <h3 className="text-primary">
                {slice.primary?.header_left?.text || "Default Header"}
              </h3>
              <h2>{slice.primary?.title_left?.text || "Default Title"}</h2>
              {slice.primary?.subtitle_left?.richText ? (
                <PrismicRichText field={slice.primary.subtitle_left.richText} />
              ) : (
                <h6 className="text-muted">Default Subtitle</h6>
              )}
            </div>
          </Col>
          {/* Right Column */}
          <Col xs={12} md={6}>
            <div className="content-box mb-4">
              <h3 className="text-primary">
                {slice.primary?.header_right?.text || "Default Header"}
              </h3>
              <h2>{slice.primary?.title_right?.text || "Default Title"}</h2>
              {slice.primary?.subtitle_right?.richText ? (
                <PrismicRichText field={slice.primary.subtitle_right.richText} />
              ) : (
                <h6 className="text-muted">Default Subtitle</h6>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyTwoColumnContent on PrismicPageDataBodyTwoColumnContent {
    id
    slice_type
    primary {
      header_left {
        text
        richText
      }
      title_left {
        text
        richText
      }
      subtitle_left {
        text
        richText
      }
      header_right {
        text
        richText
      }
      title_right {
        text
        richText
      }
      subtitle_right {
        text
        richText
      }
    }
  }
    fragment HomepageDataBodyTwoColumnContent on PrismicHomepageDataBodyTwoColumnContent {
    id
    slice_type
    primary {
      header_left {
        text
        richText
      }
      title_left {
        text
        richText
      }
      subtitle_left {
        text
        richText
      }
      header_right {
        text
        richText
      }
      title_right {
        text
        richText
      }
      subtitle_right {
        text
        richText
      }
    }
  }
`;
