import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"

export const CtaWithFullWidthImage = ({ slice }) => {
  // console.log("CtaWithFullWidthImage Slice: ", slice)

  return (
    <div
      className={`cta_with_full_width_image style_number_${slice.primary.style_number}`}
    >
      <section
        className={(() => {
          switch (slice.primary.style_number) {
            case "1":
              return "bg-c-15 py-st-2"
            case "2":
              return "bg-c-7 py-st-2"
            default:
              return ""
          }
        })()}
      >
        <Container>
          <Row
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return ""
                case "2":
                  return ""
                default:
                  return ""
              }
            })()}
          >
            <PrismicRichText
              field={slice.primary.title.richText}
              components={{
                heading2: ({ children }) => (
                  <h2
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "heading-st fs-st-1 mb-3 mb-md-4 text-center"
                        case "2":
                          return "heading-st fs-st-5 mb-3 mb-md-4 text-center"
                        default:
                          return ""
                      }
                    })()}
                  >
                    {children}
                  </h2>
                ),
              }}
            />
            <PrismicRichText
              field={slice.primary.subtitle.richText}
              components={{
                paragraph: ({ children }) => (
                  <p
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "paragraph-st fs-st-10 mb-4 px-3 px-md-5 px-lg-5 text-center"
                        case "2":
                          return "paragraph-st fs-st-10 mb-4 px-3 px-md-5 px-lg-5 text-center"
                        default:
                          return ""
                      }
                    })()}
                  >
                    {children}
                  </p>
                ),
              }}
            />
            <Col
              className={(() => {
                switch (slice.primary.style_number) {
                  case "1":
                    return "text-center"
                  case "2":
                    return "text-center"
                  default:
                    return ""
                }
              })()}
            >
              <GatsbyImage
                className={(() => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return "filter-st-1"
                    case "2":
                      return "rounded-10"
                    default:
                      return ""
                  }
                })()}
                image={slice.primary.image.gatsbyImageData}
                alt={slice.primary.image.alt}
              />
              <div className="d-flex justify-content-center pt-5 pb-0">
                <PrismicLink
                  field={slice.primary.button_link}
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "btn-st"
                      case "2":
                        return ""
                      default:
                        return ""
                    }
                  })()}
                >
                  {slice.primary.button_label}
                </PrismicLink>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCtaWithFullWidthImage on PrismicPageDataBodyCtaWithFullWidthImage {
    id
    slice_type
    primary {
      style_number
      button_link {
        url
        target
        link_type
      }
      button_label
      image {
        alt
        gatsbyImageData
        url
      }
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
  }
`
