import React, { useState } from "react"
import { Container, Dropdown, Row } from "react-bootstrap"

export default function CustomDropdown({ toggleContext, menuContext }) {
  const [showDropdown, setShowDropdown] = useState(false)

  const handleClick = () => {
    setShowDropdown(showDropdown ? false : true)
  }

  const handleMouseEnter = () => {
    setShowDropdown(true)
  }

  const handleMouseLeave = () => {
    setShowDropdown(false)
  }

  const handleToggle = isOpen => {
    setShowDropdown(isOpen)
  }

  return (
    <Dropdown
      className="nav-link py-0 py-lg-2 nav-item m-lg-auto position-static"
      bsPrefix="dropdown"
      show={showDropdown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onToggle={handleToggle}
    >
      <Dropdown.Toggle
        as="a"
        id="dropdown-button-1"
        role="button"
        className="d-block my-2"
        onClick={handleClick}
      >
        {toggleContext}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-st-1 py-0 pt-lg-2"
        show={showDropdown}
      >
        <Container>
          <Row className="d-flex flex-row">
            {menuContext}
          </Row>
        </Container>
      </Dropdown.Menu>
    </Dropdown>
  )
}
