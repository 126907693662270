import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const InfoWithLeftImage = ({ slice }) => {
  // console.log("InfoWithLeftImage Slice: ", slice)

  return (
    <div
      className={`info_with_left_image style_number_${slice.primary.style_number}`}
    >
      {slice.primary.style_number === "0" ? null : (
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "bg-c-7 py-st-2"
              case "2":
                return "bg-c-7 py-st-2"
              case "3":
                return "bg-c-7 py-2"
              case "4":
                return "bg-c-7 py-2"
              case "5":
                return "bg-c-7 py-st-2"
              case "6":
                return "bg-c-7 py-st-2"
              default:
                return ""
            }
          })()}
        >
          <Container
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return ""
                case "2":
                  return ""
                case "3":
                  return "bg-c-4 rounded-20 p-3"
                case "4":
                  return "bg-white rounded-20 p-3"
                case "5":
                  return ""
                case "6":
                  return ""
                default:
                  return ""
              }
            })()}
          >
            <Row className="justify-content-center align-items-center">
              <Col
                className={(() => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return "col-12 col-md-9 col-lg-6 p-0 filter-st-1"
                    case "2":
                      return "col-12 col-md-9 col-lg-6 text-lg-center px-0 filter-st-1"
                    case "3":
                      return "col-12 col-md-9 col-lg-6 px-lg-5 text-center m-auto"
                    case "4":
                      return "col-12 col-md-9 col-lg-6 px-lg-5 text-center m-auto"
                    case "5":
                      return "col-12 col-md-9 col-lg-6 p-0 filter-st-1"
                    case "6":
                      return "col-12 col-md-9 col-lg-6 p-0 "
                    default:
                      return ""
                  }
                })()}
              >
                <GatsbyImage
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "rounded-st-3 clip-st-2"
                      case "2":
                        return "rounded-st-3 clip-st-4 bg-white"
                      case "3":
                        return "rounded-10 img-st-6"
                      case "4":
                        return "rounded-10 img-st-6"
                      case "5":
                        return "rounded-st-3 clip-st-3"
                      case "6":
                        return "img-st-6-a"
                      default:
                        return ""
                    }
                  })()}
                  image={slice.primary.image.gatsbyImageData}
                  alt={slice.primary.image.alt}
                />
              </Col>
              <Col
                className={(() => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return "col-12 col-md-9 col-lg-6 m-auto ps-lg-5"
                    case "2":
                      return "col-12 col-md-9 col-lg-6 m-auto ps-lg-5 py-2"
                    case "3":
                      return "col-12 col-md-9 col-lg-6 m-auto pe-lg-5"
                    case "4":
                      return "col-12 col-md-9 col-lg-6 m-auto pe-lg-5"
                    case "5":
                      return "col-12 col-md-9 col-lg-6 m-auto ps-lg-5"
                    case "6":
                      return "col-12 col-md-9 col-lg-6 m-auto ps-lg-5"
                    default:
                      return ""
                  }
                })()}
              >
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading2: ({ children }) => (
                      <h2
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "heading-st fs-st-7 py-3 pt-lg-0 pb-lg-2 pb-xl-3"
                            case "2":
                              return "heading-st fs-st-7 py-3 pt-lg-0 pb-lg-2 pb-xl-3"
                            case "3":
                              return "heading-st fs-st-7 py-3 pt-lg-0 pb-lg-2 pb-xl-3"
                            case "4":
                              return "heading-st fs-st-7 py-3 pt-lg-0 pb-lg-2 pb-xl-3"
                            case "5":
                              return "heading-st fs-st-7 py-3 pt-lg-0 pb-lg-2 pb-xl-3"
                            case "6":
                              return "heading-st fs-st-7 py-3 pt-lg-0 pb-lg-2 pb-xl-3"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </h2>
                    ),
                  }}
                />
                <PrismicRichText
                  field={slice.primary.subtitle.richText}
                  components={{
                    heading2: ({ children }) => (
                      <h2
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return ""
                            case "2":
                              return ""
                            case "3":
                              return "heading-st fs-st-7 my-3 link-in-context-st-2 text-white"
                            case "4":
                              return "heading-st fs-st-7 my-3 link-in-context-st-2"
                            case "5":
                              return ""
                            case "6":
                              return ""
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </h2>
                    ),
                    paragraph: ({ children }) => (
                      <p
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "paragraph-st fs-st-11 pb-3"
                            case "2":
                              return "paragraph-st fs-st-11 pb-3"
                            case "3":
                              return "paragraph-st fs-st-11 pb-3 text-white"
                            case "4":
                              return "paragraph-st fs-st-11 pb-3"
                            case "5":
                              return "paragraph-st fs-st-11"
                            case "6":
                              return "paragraph-st fs-st-11"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </p>
                    ),
                    list: ({ children }) => (
                      <ul
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "ul-st-1 fs-st-11"
                            case "2":
                              return "ul-st-1 fs-st-11"
                            case "3":
                              return "ps-4"
                            case "4":
                              return "ps-4"
                            case "5":
                              return ""
                            case "6":
                              return ""
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </ul>
                    ),
                    listItem: ({ children }) => (
                      <li
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "li-st-1 paragraph-st fs-st-11"
                            case "2":
                              return "li-st-1 paragraph-st fs-st-11"
                            case "3":
                              return "py-1 text-white"
                            case "4":
                              return "py-1"
                            case "5":
                              return ""
                            case "6":
                              return ""
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </li>
                    ),
                  }}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithLeftImage on PrismicPageDataBodyInfoWithLeftImage {
    id
    slice_type
    primary {
      style_number
      image {
        gatsbyImageData
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }
`
