export const idMaker = string => {
  if (!string) return null
  return string
    .trim()
    .toLowerCase()
    .replaceAll(" ", "-")
    .replaceAll("?", "")
    .replaceAll("'", "")
    .replaceAll(",", "")
    .replaceAll(".", "")
}
