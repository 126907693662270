import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import { PrismicRichText, PrismicLink } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

export const Footer = ({ footer }) => {
  return (
    <div className="footer">
      <div className="bg-c-8 py-sm-1 py-md-2 py-lg-2">
        <Container>
          <Row className="px-3 pt-4 pb-1 rounded-st-1">
            <Col className="col-12 col-md-6 col-xl-3 px-0 my-1 my-md-2 px-md-2 my-lg-3 px-lg-3">
              <div className="bg-st-4 h-100 p-4">
                <PrismicRichText
                  field={footer ? footer.firstcategoryname.richText : null}
                  components={{
                    heading5: ({ children }) => (
                      <h5 className="heading-st footer-h-st mb-3 text-white">
                        {children}
                      </h5>
                    ),
                  }}
                />
                {footer
                  ? footer.first_category_links.map((item, index) => (
                    <div
                      key={`footer-first-category-links-${index}`}
                      className="my-1"
                    >
                      <PrismicLink field={item.link}>
                        <PrismicRichText
                          field={item.label.richText}
                          components={{
                            paragraph: ({ children }) => (
                              <p className="paragraph-st text-white footer-p-st">
                                {children}
                              </p>
                            ),
                          }}
                        />
                      </PrismicLink>
                    </div>
                  ))
                  : null}
              </div>
            </Col>
            <Col className="col-12 col-md-6 col-xl-3 px-0 my-1 my-md-2 px-md-2 my-lg-3 px-lg-3">
              <div className="bg-st-4 h-100 p-4">
                <PrismicRichText
                  field={footer ? footer.second_category_name.richText : null}
                  components={{
                    heading5: ({ children }) => (
                      <h5 className="heading-st footer-h-st mb-3 text-white">
                        {children}
                      </h5>
                    ),
                  }}
                />
                {footer
                  ? footer.second_category_links.map((item, index) => (
                    <div
                      key={`footer-second-category-links-${index}`}
                      className="my-1"
                    >
                      <PrismicLink field={item.link}>
                        <PrismicRichText
                          field={item.label.richText}
                          components={{
                            paragraph: ({ children }) => (
                              <p className="paragraph-st text-white footer-p-st">
                                {children}
                              </p>
                            ),
                          }}
                        />
                      </PrismicLink>
                    </div>
                  ))
                  : null}
              </div>
            </Col>
            <Col className="col-12 col-md-6 col-xl-3 px-0 my-1 my-md-2 px-md-2 my-lg-3 px-lg-3">
              <div className="bg-st-4 h-100 p-4">
                <PrismicRichText
                  field={footer ? footer.third_category_name.richText : null}
                  components={{
                    heading5: ({ children }) => (
                      <h5 className="heading-st footer-h-st mb-3 text-white">
                        {children}
                      </h5>
                    ),
                  }}
                />
                {footer
                  ? footer.third_category_links.map((item, index) => (
                    <div key={`footer-third-category-links-${index}`} className="my-1">
                      <PrismicLink field={item.link}>
                        <PrismicRichText
                          field={item.label.richText}
                          components={{
                            paragraph: ({ children }) => (
                              <p className="paragraph-st text-white footer-p-st">
                                {children}
                              </p>
                            ),
                          }}
                        />
                      </PrismicLink>
                    </div>
                  ))
                  : null}

                {/* Social Links Row */}
                <Row className="d-flex justify-content-start mt-3">
                  <Col className="col-auto">
                    <PrismicLink field={footer.social_link_1}>
                      <GatsbyImage
                        className="social-icon"
                        image={footer.social_image_1.gatsbyImageData}
                        alt={footer.social_image_1.alt || "Social Icon 1"}
                        style={{ maxWidth: "22px" }}
                      />
                    </PrismicLink>
                  </Col>
                  <Col className="col-auto">
                    <PrismicLink field={footer.social_link_2}>
                      <GatsbyImage
                        className="social-icon"
                        image={footer.social_image_2.gatsbyImageData}
                        alt={footer.social_image_2.alt || "Social Icon 2"}
                        style={{ maxWidth: "22px" }}
                      />
                    </PrismicLink>
                  </Col>
                  <Col className="col-auto">
                    <PrismicLink field={footer.social_link_3}>
                      <GatsbyImage
                        className="social-icon"
                        image={footer.social_image_3.gatsbyImageData}
                        alt={footer.social_image_3.alt || "Social Icon 2"}
                        style={{ maxWidth: "22px" }}
                      />
                    </PrismicLink>
                  </Col>
                  <Col className="col-auto">
                    <PrismicLink field={footer.social_link_4}>
                      <GatsbyImage
                        className="social-icon"
                        image={footer.social_image_4.gatsbyImageData}
                        alt={footer.social_image_4.alt || "Social Icon 2"}
                        style={{ maxWidth: "22px" }}
                      />
                    </PrismicLink>
                  </Col>
                  <Col className="col-auto">
                    <PrismicLink field={footer.social_link_5}>
                      <GatsbyImage
                        className="social-icon"
                        image={footer.social_image_5.gatsbyImageData}
                        alt={footer.social_image_5.alt || "Social Icon 2"}
                        style={{ maxWidth: "22px" }}
                      />
                    </PrismicLink>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col className="col-12 col-md-6 col-xl-3 px-0 my-1 my-md-2 px-md-2 my-lg-3 px-lg-3">
              <div className="bg-st-4 h-100 p-4">
                <PrismicRichText
                  field={footer ? footer.title.richText : null}
                  components={{
                    heading5: ({ children }) => (
                      <h5 className="heading-st footer-h-st mb-3 text-white">
                        {children}
                      </h5>
                    ),
                  }}
                />
                <PrismicRichText
                  field={footer ? footer.subtitle.richText : null}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="paragraph-st text-white footer-p-st">
                        {children}
                      </p>
                    ),
                  }}
                />
                <div className="mt-4">
                  <PrismicLink
                    field={footer.first_link}
                    className="d-flex justify-content-center"
                  >
                    <GatsbyImage
                      className="img-st-5"
                      image={
                        footer ? footer.first_link_image.gatsbyImageData : null
                      }
                      alt={footer ? footer.first_link_image.alt : null}
                    />
                  </PrismicLink>
                </div>
                <div className="mt-4">
                  <PrismicLink
                    field={footer.second_link}
                    className="d-flex justify-content-center"
                  >
                    <GatsbyImage
                      className="img-st-5"
                      image={
                        footer ? footer.second_link_image.gatsbyImageData : null
                      }
                      alt={footer ? footer.second_link_image.alt : null}
                    />
                  </PrismicLink>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="px-4 fs-st-24 text-b9b9b9">
            <Col className="col-12 col-md-6 d-flex flex-row justify-content-center justify-content-md-start">
              © 2023 Grub24 Limited
            </Col>
            <Col className="col-12 col-md-6 d-flex flex-row justify-content-center justify-content-md-end">
              Company number: 11873507
            </Col>
          </Row>
        </Container>
      </div>
      <script src="//eu.fw-cdn.com/12756267/746640.js" chat="true"></script>
    </div>
  );
};

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    _previewable
    type
    lang
    data {
      title {
        richText
      }
      subtitle {
        richText
      }
      first_link {
        url
        target
        link_type
      }
      first_link_image {
        gatsbyImageData
        alt
      }
      second_link {
        url
        target
        link_type
      }
      second_link_image {
        gatsbyImageData
        alt
      }
      firstcategoryname {
        richText
      }
      first_category_links {
        link {
          url
          target
          link_type
        }
        label {
          richText
        }
      }
      second_category_name {
        richText
      }
      second_category_links {
        link {
          url
          target
          link_type
        }
        label {
          richText
        }
      }
      third_category_name {
        richText
      }
      third_category_links {
        link {
          url
          target
          link_type
        }
        label {
          richText
        }
      }
      social_link_1 {
        url
        target
        link_type
      }
      social_image_1 {
        gatsbyImageData
        alt
      }
      social_link_2 {
        url
        target
        link_type
      }
      social_image_2 {
        gatsbyImageData
        alt
      }
       social_link_3 {
        url
        target
        link_type
      }
      social_image_3 {
        gatsbyImageData
        alt
      }
      social_link_4 {
        url
        target
        link_type
      }
      social_image_4 {
        gatsbyImageData
        alt
      } 
      social_link_5 {
        url
        target
        link_type
      }
      social_image_5 {
        gatsbyImageData
        alt
      } 
    }
  }
`;
