import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectCards } from "swiper"
import { idMaker } from "../utils/idMaker"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-cards"
import "swiper/css/navigation"
// import "swiper/css/pagination"

export const Testimonials = ({ slice }) => {
  // console.log("Testimonials slice: ", slice)

  return (
    <div className={`testimonials`} id={idMaker(slice.primary.title.text)}>
      <div
        className="testimonial-st-1 bg-c-7 py-st-2"
        style={
          slice.primary.background_image.url
            ? {
                backgroundImage: `url(${slice.primary.background_image.url})`,
              }
            : null
        }
      >
        <h3 className="heading-st fs-st-6 py-2 py-lg-3 text-center">
          {" "}
          {slice.primary.title.text}
        </h3>
        <p className="paragraph-st fs-st-17 px-3 px-md-5 px-lg-3 text-center mb-4 mb-lg-5">
          {slice.primary.subtitle.text}
        </p>
        <Swiper
          className="mySwiper"
          loop={true}
          effect={"cards"}
          grabCursor={true}
          modules={[Autoplay, EffectCards]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          // pagination={{ clickable: true }}
          // navigation={true}
          // modules={[Navigation]}
        >
          {slice.items.map((item, index) => (
            <SwiperSlide key={`testimonials-slide-${index}`}>
              <GatsbyImage
                className="mySwiper-items"
                image={item.image.gatsbyImageData}
                alt={item.image.alt}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTestimonials on PrismicHomepageDataBodyTestimonials {
    id
    slice_type
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        richText
      }
      background_image {
        alt
        url
        gatsbyImageData
      }
    }
    items {
      image {
        alt
        url
        gatsbyImageData
      }
    }
  }

  fragment PageDataBodyTestimonials on PrismicPageDataBodyTestimonials {
    id
    slice_type
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        richText
      }
      background_image {
        alt
        url
        gatsbyImageData
      }
    }
    items {
      image {
        alt
        url
        gatsbyImageData
      }
    }
  }
`
