import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Image, Modal } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { FaPlayCircle } from "react-icons/fa" // Import a play icon from react-icons or use any play icon SVG

export const CtaWithLeftImage = ({ slice }) => {
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const youtubeEmbedUrl = `https://www.youtube.com/embed/${slice.primary.youtube_link}`

  return (
    <div className={`cta_with_left_image style_number_${slice.primary.style_number}`}>
      {slice.primary.style_number === "0" ? null : (
        <section className="bg-c-7 py-st-2">
          <Container className="mt-5">
            <Row className="">
              <Col className="px-0 col-lg-6 col-sm-12 col-12 h-min-400px">
                <div
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "position-relative h-100 bg-c-14"
                      default:
                        return ""
                    }
                  })()}
                  style={{ position: "relative", cursor: "pointer" }} // Ensure relative positioning for icon overlay
                  onClick={slice.primary.youtube_link ? handleOpenModal : null} // Only make clickable if youtube_link is present
                >
                  <Image
                    fluid
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "position-absolute start-0 bottom-0 end-0 m-auto img-st-9 filter-st-1"
                        default:
                          return ""
                      }
                    })()}
                    src={slice.primary.image.url}
                    alt={slice.primary.image.alt}
                  />

                  {/* Conditionally render play icon overlay */}
                  {slice.primary.youtube_link && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "3rem", // Adjust size of play icon
                        color: "white",
                        opacity: 0.8,
                      }}
                    >
                      <FaPlayCircle />
                    </div>
                  )}
                </div>
              </Col>
              <Col className="m-auto px-5 col-lg-6 col-md-12 col-12">
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading1: ({ children }) => <h1 className="heading-st fs-st-6 py-3">{children}</h1>,
                  }}
                />
                <PrismicRichText
                  field={slice.primary.subtitle.richText}
                  components={{
                    paragraph: ({ children }) => <p className="paragraph-st fs-st-11 py-3">{children}</p>,
                  }}
                />
                <div className="d-flex justify-content-center pt-2 pb-4">
                  {slice.primary.button_link && (
                    <PrismicLink field={slice.primary.button_link} className="btn-st">
                      {slice.primary.button_label}
                    </PrismicLink>
                  )}
                </div>
              </Col>
            </Row>
          </Container>

          {/* Modal for YouTube Video */}
          <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
            <Modal.Body className="p-0 d-flex justify-content-center align-items-center">
              <iframe
                className="embed-responsive-item"
                src={youtubeEmbedUrl}
                allowFullScreen
                title="YouTube Video"
                style={{
                  minWidth: "100%",
                  minHeight: "500px",
                  border: "none"
                }}
              />
            </Modal.Body>
          </Modal>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCtaWithLeftImage on PrismicHomepageDataBodyCtaWithLeftImage {
    id
    slice_type
    primary {
      style_number
      image {
        gatsbyImageData
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
      button_label
      button_link {
        url
        target
        link_type
      }
      youtube_link
    }
  }

  fragment PageDataBodyCtaWithLeftImage on PrismicPageDataBodyCtaWithLeftImage {
    id
    slice_type
    primary {
      style_number
      image {
        gatsbyImageData
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
      button_label
      button_link {
        url
        target
        link_type
      }
      youtube_link
    }
  }
`
