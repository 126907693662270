import React from "react"
// import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { idMaker } from "../utils/idMaker"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
// import { Container, Row, Col } from "react-bootstrap"
// import { GatsbyImage } from "gatsby-plugin-image"

export const Hero = ({ slice }) => {
  // console.log("Hero slice: ", slice)

  // let maxWidth767px = window.matchMedia("(max-width: 767px)")
  // useEffect(() => {
  //   maxWidth767px = window.matchMedia("(max-width: 767px)")
  //   console.log("maxWidth767px.matches: ", maxWidth767px.matches)
  // }, [window])

  return (
    <div className={`hero`} id={idMaker(slice.primary.first_title.text)}>
      <div className="hero-st-1">
        <div className="bg-c-7 py-st-2">
          <div
            className="container d-flex hero-st-2 filter-st-1"
            style={
              slice.primary.first_background_image.url
                ? {
                  backgroundImage: `url(${slice.primary.first_background_image.url}), url(${slice.primary.second_background_image.url})`,
                }
                : null
            }

          // style={
          //   maxWidth767px.matches
          //     ? {
          //         backgroundImage: `url(${slice.primary.second_background_image.url})`,
          //       }
          //     : {
          //         backgroundImage: `url(${slice.primary.first_background_image.url})`,
          //       }
          // }
          >
            <div className="w-50">
              <PrismicRichText
                field={slice.primary.first_title.richText}
                components={{
                  heading1: ({ children }) => (
                    <h1 className="heading-st fs-st-22 mt-3 mt-md-4 mt-lg-5 text-white pb-3">
                      {children}
                    </h1>
                  ),
                }}
              />
              <p className="paragraph-st fs-st-13 text-white">
                {slice.primary.first_subtitle.text}
              </p>
              <PrismicLink
                field={slice.primary.first_button_link}
                className="btn-st"
              >
                {slice.primary.first_button_label.text}
              </PrismicLink>
              <h5 className="heading-st fs-st-4 text-white">
                {slice.primary.second_title.text}
              </h5>
              <PrismicRichText
                field={slice.primary.second_subtitle.richText}
                components={{
                  heading5: ({ children }) => (
                    <h5 className="paragraph-st fs-st-11 text-white">
                      {children}
                    </h5>
                  ),
                }}
              />
              <div
                className="hero-st-4 d-flex align-items-center mt-sm-1 mt-md-2 mt-xxl-3 mb-2"
                style={
                  slice.primary.appended_image.url
                    ? {
                      backgroundImage: `url(${slice.primary.appended_image.url})`,
                    }
                    : null
                }
              >
                {slice.items
                  ? slice.items.map((item, index) => (
                    <div
                      className="hero-st-3 d-flex align-items-center justify-content-center"
                      key={`logos-container-${index}`}
                    >
                      <img
                        alt={item.logos.alt}
                        src={item.logos.url}
                        className="img-st-11"
                      />
                    </div>
                  ))
                  : null}
              </div>
            </div>
            <div className="w-50 d-flex align-items-end">
              <img
                className=""
                alt={slice.primary.front_image.alt}
                src={slice.primary.front_image.url}
              />
              {/* <GatsbyImage
                className="img-st-15"
                image={slice.primary.front_image.gatsbyImageData}
                alt={slice.primary.front_image.alt}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
    id
    slice_type
    primary {
      first_title {
        richText
        text
      }
      first_subtitle {
        richText
        text
      }
      first_button_link {
        url
        target
        link_type
      }
      first_button_label {
        richText
        text
      }
      second_title {
        richText
        text
      }
      second_subtitle {
        richText
        text
      }
      first_background_image {
        alt
        url(imgixParams: { auto: "" })
      }
      second_background_image {
        alt
        url(imgixParams: { auto: "" })
      }
      front_image {
        gatsbyImageData
        alt
        url
      }
      appended_image {
        alt
        url(imgixParams: { auto: "" })
      }
    }
    items {
      logos {
        gatsbyImageData
        alt
        url
      }
    }
  }
  fragment PageDataBodyHero on PrismicPageDataBodyHero {
    id
    slice_type
    primary {
      first_title {
        richText
        text
      }
      first_subtitle {
        richText
        text
      }
      first_button_link {
        url
        target
        link_type
      }
      first_button_label {
        richText
        text
      }
      second_title {
        richText
        text
      }
      second_subtitle {
        richText
        text
      }
     
      front_image {
        gatsbyImageData
        alt
        url
      }
      
    }
    items {
      logos {
        gatsbyImageData
        alt
        url
      }
    }
  }
`
