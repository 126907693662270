import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Autoplay } from "swiper"

import "swiper/css"
import "swiper/css/effect-cards"
import "swiper/css/navigation"

export const PartnersWithCardsImages = ({ slice }) => {
  // console.log("PartnersWithCardsImages Slice: ", slice)

  return (
    <div
      className={`partners_with_cards_images style_number_${slice.primary.style_number}`}
    >
      <section
        className={(() => {
          switch (slice.primary.style_number) {
            case "1":
              return "bg-c-2 py-st-2"
            case "2":
              return "bg-c-7 py-st-2"
            default:
              return ""
          }
        })()}
      >
        <Container
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return ""
              case "2":
                return ""
              default:
                return ""
            }
          })()}
        >
          <div
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return "text-center"
                case "2":
                  return "text-center"
                default:
                  return ""
              }
            })()}
          >
            <PrismicRichText
              field={slice.primary.title.richText}
              components={{
                heading2: ({ children }) => (
                  <h2
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "heading-st fs-st-6 pb-3 pb-md-4 pb-lg-5 text-center"
                        case "2":
                          return "heading-st fs-st-6 pb-3 pb-md-4 pb-lg-5 text-center"
                        default:
                          return ""
                      }
                    })()}
                  >
                    {children}
                  </h2>
                ),
                heading3: ({ children }) => (
                  <h3
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "heading-st fs-st-3 pb-3 pb-md-4 pb-lg-5 text-center"
                        case "2":
                          return "heading-st fs-st-8 pb-3 pb-md-4 pb-lg-5 text-center"
                        default:
                          return ""
                      }
                    })()}
                  >
                    {children}
                  </h3>
                ),
              }}
            />
            <PrismicRichText
              field={slice.primary.subtitle.richText}
              components={{
                paragraph: ({ children }) => (
                  <p
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "paragraph-st fs-st-11 text-center"
                        case "2":
                          return "paragraph-st fs-st-11 text-center"
                        default:
                          return ""
                      }
                    })()}
                  >
                    {children}
                  </p>
                ),
              }}
            />
          </div>
          <Row>
            <Col>
              <Swiper
                className="swiper-partners"
                effect={"coverflow"}
                modules={[FreeMode, Autoplay]}
                grabCursor={true}
                loop={true}
                loopFillGroupWithBlank={true}
                // freeMode={true}
                // freeModeMomentum={false}
                // freeModeFluid={true}
                // freeMode={{
                //   enabled: true,
                //   sticky: true,
                // }}
                autoplay={{
                  delay: 1700,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 50,
                  },
                  1440: {
                    slidesPerView: 7,
                    spaceBetween: 50,
                  },
                }}
              >
                {slice.items.map((item, index) => (
                  <SwiperSlide key={`testimonials-slide-${index}`}>
                    <div className="">
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.image.alt}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPartnersWithCardsImages on PrismicHomepageDataBodyPartnersWithCardsImages {
    id
    slice_type
    items {
      image {
        alt
        url
        gatsbyImageData
      }
      is_main_card
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
    primary {
      style_number
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
  }

  fragment PageDataBodyPartnersWithCardsImages on PrismicPageDataBodyPartnersWithCardsImages {
    id
    slice_type
    items {
      image {
        alt
        url
        gatsbyImageData
      }
      is_main_card
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
    primary {
      style_number
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
  }
`
