import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"
// import { GatsbyImage } from "gatsby-plugin-image"

export const TestSlice = ({ slice }) => {
  // console.log("TestSlice Slice: ", slice)

  return (
    <div className={`test_slice`}>
      <section className="bg-c-7 py-st-2">
        <Container className="">
          <Row className="justify-content-center">
            <Col>
              <PrismicRichText
                field={slice.primary.main.richText}
                components={{
                  paragraph: ({ children, key }) => (
                    <p className="paragraph-st fs-st-11 py-2 lh-md" key={key}>
                      {children}
                    </p>
                  ),
                  heading3: ({ children, key }) => (
                    <h3 className="heading-st fs-st-8 py-3" key={key}>
                      {children}
                    </h3>
                  ),
                  image: ({ node, key }) => {
                    return (
                      <div
                        key={key}
                        className="block-img d-flex align-items-start justify-content-center p-lg-4 my-2"
                        // className="align-items-start"
                      >
                        <img
                          className="img-fluid"
                          src={node.url}
                          alt={node.alt ?? undefined}
                          data-copyright={
                            node.copyright ? node.copyright : undefined
                          }
                          loading="lazy"
                        />
                      </div>
                    )
                  },
                  oListItem: ({ children, key }) => (
                    <li className="paragraph-st fs-st-11" key={key}>
                      {children}
                    </li>
                  ),
                  listItem: ({ children, key }) => (
                    <li className="paragraph-st fs-st-11" key={key}>
                      {children}
                    </li>
                  ),
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyTestSlice on PrismicPageDataBodyTestSlice {
    id
    slice_type
    primary {
      main {
        text
        richText
      }
    }
  }
`
