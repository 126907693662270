import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const InfoWithBackgroundImage = ({ slice }) => {
  // console.log("InfoWithBackgroundImage Slice: ", slice)

  return (
    <div
      className={`info_with_background_image style_number_${slice.primary.style_number}`}
    >
      <section
        className={(() => {
          switch (slice.primary.style_number) {
            case "1":
              return "bg-st-2"
            case "2":
              return "bg-st-2"
            default:
              return ""
          }
        })()}
        style={(() => {
          switch (slice.primary.style_number) {
            case "1":
              return {
                backgroundImage: `url(${slice.primary.image.url})`,
              }
            case "2":
              return {
                backgroundImage: `linear-gradient(to bottom,rgba(0, 127, 98, 0.7),rgba(255, 255, 143, 0.5)),url(${slice.primary.image.url})`,
              }
            default:
              return ""
          }
        })()}
      >
        <Container className="h-100">
          <Row className="h-100 text-center">
            <Col
              className={(() => {
                switch (slice.primary.style_number) {
                  case "1":
                    return "text-white d-flex flex-column justify-content-center align-items-center"
                  case "2":
                    return "text-white d-flex flex-column justify-content-center align-items-center"
                  default:
                    return ""
                }
              })()}
            >
              <PrismicRichText
                field={slice.primary.title.richText}
                components={{
                  heading2: ({ children }) => (
                    <h2
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "heading-st text-white fs-st-6 py-5"
                          case "2":
                            return "heading-st text-white fs-st-6 py-5"
                          default:
                            return ""
                        }
                      })()}
                    >
                      {children}
                    </h2>
                  ),
                }}
              />
              <PrismicRichText
                field={slice.primary.subtitle.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "paragraph-st text-white fs-st-10"
                          case "2":
                            return "paragraph-st text-white fs-st-10"
                          default:
                            return ""
                        }
                      })()}
                    >
                      {children}
                    </p>
                  ),
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithBackgroundImage on PrismicPageDataBodyInfoWithBackgroundImage {
    id
    slice_type
    primary {
      image {
        alt
        gatsbyImageData
        url
      }
      style_number
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
  }
`
