import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Card, Badge } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

export const Posts = ({ slice }) => {
  // console.log("Posts Slice: ", slice)

  const allPostsData = useStaticQuery(graphql`
    query myQuery {
      allPrismicBlog(sort: { data: { date: DESC } }) {
        nodes {
          data {
            article_category
            author_image {
              gatsbyImageData
              url
              alt
            }
            author_name {
              richText
              text
            }
            date(formatString: "DD-MM-YYYY")
            description {
              richText
              text
            }
            display_title
            main_image {
              alt
              url
              gatsbyImageData
            }
            abstract {
              richText
              text
            }
            title {
              richText
              text
            }
          }
          uid
        }
      }
    }
  `)

  const nodes = allPostsData.allPrismicBlog.nodes || []

  const [selectedCategory, setSelectedCategory] = useState("All")

  const uniqueCategories = ["All"]
  for (const item of nodes) {
    if (
      !uniqueCategories.includes(item.data.article_category) &&
      item.data.article_category !== null
    ) {
      uniqueCategories.push(item.data.article_category)
    }
  }

  return (
    <div className={`posts`}>
      <section className="bg-c-7 py-st-2">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-12 col-lg-8 mb-3 mb-md-4">
              <PrismicRichText
                field={slice.primary.title.richText}
                components={{
                  heading1: ({ children }) => (
                    <h1 className="heading-st fs-st-1 text-center py-3 py-md-4">
                      {children}
                    </h1>
                  ),
                }}
              />
              <PrismicRichText
                field={slice.primary.subtitle.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p className="paragraph-st fs-st-15 text-center text-828585">
                      {children}
                    </p>
                  ),
                }}
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col className="d-flex justify-content-center flex-wrap">
              {uniqueCategories.map((item, index) => (
                <div key={`posts-key-${index}`}>
                  <button
                    onClick={() => setSelectedCategory(item)}
                    className={`btn-st btn-st-3 m-1 ${
                      selectedCategory === item ? "btn-st-3-active" : ""
                    }`}
                  >
                    {item}
                  </button>
                </div>
              ))}
            </Col>
          </Row>

          <Row className="justify-content-center">
            {nodes.map((item, index) => {
              if (
                selectedCategory === "All" ||
                item.data.article_category === selectedCategory
              ) {
                return (
                  <Col
                    key={`posts-key2-${index}`}
                    className="g-4 col-12 col-md-6 col-lg-4"
                  >
                    <PrismicLink href={`/blogs/${item.uid}`} className="a-st-1">
                      <Card className="h-100 shadow rounded-st-10">
                        <div className="m-auto position-relative">
                          <GatsbyImage
                            className="img-wrapper-st-2 rounded-st-11-t"
                            image={item.data.main_image.gatsbyImageData}
                            alt={item.data.main_image.alt}
                          />
                          <div className="badge-st-1">
                            <Badge bg="success">
                              {item.data.article_category}
                            </Badge>
                          </div>
                        </div>
                        <Card.Body>
                          <PrismicRichText
                            field={item.data.title.richText}
                            components={{
                              heading1: ({ children }) => (
                                <h3 className="heading-st mb-3 text-center lh-base">
                                  {children}
                                </h3>
                              ),
                            }}
                          />

                          <div className="paragraph-st">
                            {item.data.abstract.text.split(" ").length > 40
                              ? item.data.abstract.text
                                  .split(" ")
                                  .slice(0, 40)
                                  .join(" ") + " ..."
                              : item.data.abstract.text}
                          </div>
                        </Card.Body>

                        <div className="d-flex flex-row justify-content-center p-2">
                          <div className="">
                            <GatsbyImage
                              className="img-wrapper-st-3 me-2"
                              image={item.data.author_image.gatsbyImageData}
                              alt={item.data.author_image.alt}
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <PrismicRichText
                              field={item.data.author_name.richText}
                              components={{
                                heading6: ({ children }) => (
                                  <h6 className="heading-st">{children}</h6>
                                ),
                              }}
                            />
                            <p className="text-828585">{item.data.date}</p>
                          </div>
                        </div>
                      </Card>
                    </PrismicLink>
                  </Col>
                )
              } else {
                return null
              }
            })}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyPosts on PrismicPageDataBodyPosts {
    id
    slice_type
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        richText
      }
    }
  }
`
