import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Card } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

export const CtaWithCardsDoubleLinks = ({ slice }) => {
  // console.log("CtaWithCardsDoubleLinks Slice: ", slice)

  return (
    <div className={`cta_with_cards_double_links`}>
      <section className="bg-st-1 py-st-2">
        <Container>
          <PrismicRichText
            field={slice.primary.title.richText}
            components={{
              heading2: ({ children }) => (
                <h2 className="heading-st text-white fs-st-6 text-center pb-3 pb-md-4 pt-lg-2 pb-lg-5">
                  {children}
                </h2>
              ),
            }}
          />
          <Row xs={1} sm={2} md={2} lg={2} className="g-4">
            {slice.items.map((item, index) => (
              <Col
                key={`call-to-action-with-cards-call_to_action_with_cards_images-${index}`}
              >
                <Card className="border-0 h-100 bg-transparent">
                  <div className="">
                    <GatsbyImage
                      className="rounded-10 filter-st-1"
                      image={item.image.gatsbyImageData}
                      alt={item.image.alt}
                    />
                  </div>
                  <Card.Body>
                    <PrismicRichText
                      field={item.title.richText}
                      components={{
                        heading3: ({ children }) => (
                          <h3 className="card-st-2-title fs-st-12 text-white">
                            {children}
                          </h3>
                        ),
                      }}
                    />
                    <PrismicRichText
                      field={item.subtitle.richText}
                      components={{
                        paragraph: ({ children }) => (
                          <p className="card-st-2-text text-white">
                            {children}
                          </p>
                        ),
                      }}
                    />
                  </Card.Body>
                  <div className="d-flex justify-content-center">
                    {item.first_button_label ? (
                      <PrismicLink
                        field={item.first_button_link}
                        className="btn-st btn-st-1"
                      >
                        {item.first_button_label}
                      </PrismicLink>
                    ) : null}
                    {item.second_button_label ? (
                      <PrismicLink field={item.second_button_link}>
                        {item.second_button_label}
                      </PrismicLink>
                    ) : null}

                    {item.first_link_image.gatsbyImageData ? (
                      <div className="m-2">
                        <PrismicLink field={item.first_button_link}>
                          <GatsbyImage
                            image={item.first_link_image.gatsbyImageData}
                            alt={item.first_link_image.alt}
                          />
                        </PrismicLink>
                      </div>
                    ) : null}
                    {item.second_link_image.gatsbyImageData ? (
                      <div className="m-2">
                        <PrismicLink field={item.second_button_link}>
                          <GatsbyImage
                            image={item.second_link_image.gatsbyImageData}
                            alt={item.second_link_image.alt}
                          />
                        </PrismicLink>
                      </div>
                    ) : null}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCtaWithCardsDoubleLinks on PrismicPageDataBodyCtaWithCardsDoubleLinks {
    id
    slice_type
    primary {
      title {
        text
        richText
      }
    }
    items {
      title {
        text
        richText
      }
      subtitle {
        text
        richText
      }
      first_button_label
      first_button_link {
        url
        target
        link_type
      }
      second_button_label
      second_button_link {
        url
        target
        link_type
      }
      image {
        gatsbyImageData
        alt
        url
      }
      first_link_image {
        gatsbyImageData
        alt
        url
      }
      second_link_image {
        gatsbyImageData
        alt
        url
      }
    }
  }
`
