import * as React from "react"
import { SliceZone } from "@prismicio/react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { components } from "../slices"
// import { useScrollRestoration } from "gatsby"

const HomepageTemplate = ({ data }) => {
  // const scrollRestoration = useScrollRestoration(`homepage-template-scroll`)
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const navbar = data.prismicNavbar || {}
  const footer = data.prismicFooter || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout navbar={navbar.data} activeDocMeta={activeDoc} footer={footer.data}>
      <SliceZone
        slices={homepage.data.body}
        components={components}
      // {...scrollRestoration}
      />
    </Layout>
  )
}

export const Head = ({ data }) => {
  return (
    <Seo
      title={data.prismicHomepage.data.meta_title}
      description={data.prismicHomepage.data.meta_description}
      keywords={data.prismicHomepage.data.meta_keywords}
    />
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        display_title
        meta_title
        meta_description
        meta_keywords {
          keyword
        }
        body {
          ... on PrismicSlice {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyBanner
          ...HomepageDataBodyCtaWithRightImage
          ...HomepageDataBodyCtaWithLeftImage
          ...HomepageDataBodyFaq
          ...HomepageDataBodyTwoColumnContent
          ...HomepageDataBodyFeaturesWithCardsImages
          ...HomepageDataBodyHero
          ...HomepageDataBodyIsometricWithTabsImages
          ...HomepageDataBodyJoinUs
          ...HomepageDataBodyServices
          ...HomepageDataBodyStepsWithCardsImages
          ...HomepageDataBodyTestimonials
          ...HomepageDataBodyPartnersWithCardsImages
          ...HomepageBodyRedirectWithCardsImages
          ...HomepageDataBodyHeadline
        }
      }
    }
    prismicNavbar(lang: { eq: $lang }) {
      ...NavbarFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
