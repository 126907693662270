import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { idMaker } from "../utils/idMaker"

export const StepsWithCardsImages = ({ slice }) => {
  // console.log("StepsWithCardsImages slice: ", slice)

  return (
    <div
      className={`steps_with_cards_images`}
      id={idMaker(slice.primary.title.text)}
    >
      <section className="bg-c-7 py-st-2">
        <Container>
          <Row>
            <Col className="mb-3 mb-md-4 mb-lg-5">
              <PrismicRichText
                field={slice.primary.title.richText}
                components={{
                  heading2: ({ children }) => (
                    <h2 className="heading-st fs-st-7 text-center mb-3">
                      {children}
                    </h2>
                  ),
                }}
              />
              <PrismicRichText
                field={slice.primary.subtitle.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p className="paragraph-st fs-st-10 text-center">
                      {children}
                    </p>
                  ),
                }}
              />
            </Col>
          </Row>
          <Row
            xs={1}
            sm={2}
            lg={slice.items.length % 2 === 0 ? 4 : 3}
            className="justify-content-center"
          >
            {slice.items.map((item, index) => (
              <Col key={`steps-with-cards-${index}`}>
                <Card className="bg-c-7 border-0 shadow-none h-100 py-3 py-lg-0">
                  <div
                    className={`m-auto ${item.is_main_item ? "mt-lg-3" : "mt-lg-5"
                      }`}
                  >
                    <GatsbyImage
                      className="img-st-9 filter-st-1"
                      image={item.numbers_image.gatsbyImageData}
                      alt={item.numbers_image.alt}
                    />
                  </div>
                  <Card.Body className="">
                    <PrismicRichText
                      field={item.title.richText}
                      components={{
                        heading4: ({ children }) => (
                          <h4 className="card-st-2-title fs-st-16 my-1 my-md-2 my-lg-3">
                            {children}
                          </h4>
                        ),
                      }}
                    />
                    <PrismicRichText
                      field={item.subtitle.richText}
                      components={{
                        paragraph: ({ children }) => (
                          <p className="card-st-2-text fs-st-11">{children}</p>
                        ),
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyStepsWithCardsImages on PrismicHomepageDataBodyStepsWithCardsImages {
    id
    slice_type
    items {
      is_main_item
      numbers_image {
        alt
        url
        gatsbyImageData
      }
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
    primary {
      title {
        richText
        text
      }
      subtitle {
        richText
        text
      }
    }
  }

  fragment PageDataBodyStepsWithCardsImages on PrismicPageDataBodyStepsWithCardsImages {
    id
    slice_type
    items {
      is_main_item
      numbers_image {
        alt
        url
        gatsbyImageData
      }
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
    primary {
      title {
        richText
        text
      }
      subtitle {
        richText
        text
      }
    }
  }
`
