import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Card } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

export const CtaWithCardsImages = ({ slice }) => {
  // console.log("CtaWithCardsImages Slice: ", slice)

  return (
    <div
      className={`cta_with_cards_images style_number_${slice.primary.style_number}`}
    >
      <section className="bg-c-7 py-st-2">
        <Container>
          <PrismicRichText
            field={slice.primary.title.richText}
            components={{
              heading2: ({ children }) => (
                <h2
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "heading-st fs-st-5 text-center pb-3 pb-md-4 pb-lg-5"
                      case "2":
                        return ""
                      default:
                        return ""
                    }
                  })()}
                >
                  {children}
                </h2>
              ),
            }}
          />
          <Row
            xs={1}
            sm={2}
            md={2}
            lg={2}
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return "g-4"
                case "2":
                  return ""
                default:
                  return ""
              }
            })()}
          >
            {slice.items.map((item, index) => (
              <Col key={`cta-with-cards-images-${index}`}>
                <Card
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "h-100 shadow rounded-3 py-3 py-md-4 py-lg-5"
                      case "2":
                        return ""
                      default:
                        return ""
                    }
                  })()}
                >
                  <div
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return ""
                        case "2":
                          return ""
                        default:
                          return ""
                      }
                    })()}
                  >
                    {item.image.gatsbyImageData ? (
                      <GatsbyImage
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return ""
                            case "2":
                              return ""
                            default:
                              return ""
                          }
                        })()}
                        image={item.image.gatsbyImageData}
                        alt={item.image.alt}
                      />
                    ) : null}
                  </div>
                  <Card.Body
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "px-xl-5 d-flex flex-column justify-content-center"
                        case "2":
                          return ""
                        default:
                          return ""
                      }
                    })()}
                  >
                    <PrismicRichText
                      field={item.title.richText}
                      components={{
                        heading3: ({ children }) => (
                          <h3
                            className={(() => {
                              switch (slice.primary.style_number) {
                                case "1":
                                  return "card-st-2-title fs-st-12"
                                case "2":
                                  return ""
                                default:
                                  return ""
                              }
                            })()}
                          >
                            {children}
                          </h3>
                        ),
                      }}
                    />
                    <PrismicRichText
                      field={item.subtitle.richText}
                      components={{
                        paragraph: ({ children }) => (
                          <p
                            className={(() => {
                              switch (slice.primary.style_number) {
                                case "1":
                                  return "card-st-2-text fs-st-11"
                                case "2":
                                  return ""
                                default:
                                  return ""
                              }
                            })()}
                          >
                            {children}
                          </p>
                        ),
                      }}
                    />
                  </Card.Body>
                  <div
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "d-flex justify-content-center"
                        case "2":
                          return ""
                        default:
                          return ""
                      }
                    })()}
                  >
                    <PrismicLink
                      field={item.button_link}
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "btn-st btn-st-2"
                          case "2":
                            return ""
                          default:
                            return ""
                        }
                      })()}
                    >
                      {item.button_label}
                    </PrismicLink>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCtaWithCardsImages on PrismicPageDataBodyCtaWithCardsImages {
    id
    slice_type
    primary {
      style_number
      title {
        text
        richText
      }
    }
    items {
      button_label
      button_link {
        url
        target
        link_type
      }
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
      image {
        gatsbyImageData
        alt
        url
      }
    }
  }
`
