import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const InfoWithFullWidthVideo = ({ slice }) => {
  // console.log("InfoWithFullWidthVideo Slice: ", slice)

  return (
    <div
      className={`info_with_full_width_video style_number_${slice.primary.style_number}`}
    >
      {slice.primary.style_number === "0" ? null : (
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "bg-c-7 py-st-2"
              default:
                return ""
            }
          })()}
        >
          <Container>
            <Row className="text-center">
              <Col>
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading2: ({ children }) => (
                      <h2 className="heading-st fs-st-8 py-3 py-md-4 py-md-5">
                        {children}
                      </h2>
                    ),
                  }}
                />
                <div>
                  <iframe
                    src={slice.primary.video_embed_link.url}
                    title={slice.primary.title.text}
                    // title={slice.primary.video.title}
                    // frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="video-container-st"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithFullWidthVideo on PrismicPageDataBodyInfoWithFullWidthVideo {
    id
    slice_type
    primary {
      style_number
      title {
        text
        richText
      }
      video_embed_link {
        url
      }
    }
  }
`
