import React from "react"
import { PrismicRichText } from "@prismicio/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { idMaker } from "../utils/idMaker"

export const IsometricWithTabsImages = ({ slice }) => {
  // console.log("IsometricWithTabsImages Slice: ", slice)

  return (
    <div
      className={`isometric_with_tabs_images style_number_${slice.primary.style_number}`}
      id={idMaker(slice.primary.title.text)}
    >
      <section className="bg-c-7 py-st-2">
        <Container className="border rounded-3 bg-white shadow-st-1">
          <Row className="justify-content-center align-items-center">
            <Col className="py-3 py-md-4 py-lg-5">
              <PrismicRichText
                field={slice.primary.title.richText}
                components={{
                  heading1: ({ children }) => <h1 className="">{children}</h1>,
                  heading2: ({ children }) => (
                    <h2 className="heading-st fs-st-6 py-2 py-lg-0 py-xl-1 text-center">
                      {children}
                    </h2>
                  ),
                  heading3: ({ children }) => (
                    <h3 className="heading-st fs-st-6 py-2 py-lg-0 py-xl-1 text-center">
                      {children}
                    </h3>
                  ),
                }}
              />
              <PrismicRichText
                field={slice.primary.subtitle.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p className="paragraph-st fs-st-11 pt-3 pb-4 px-5 text-center">
                      {children}
                    </p>
                  ),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="px-0">
              <Tabs defaultActiveKey="tab-0" className="nav-tabs-st-1" justify>
                {slice.items.map((item, index) => (
                  <Tab
                    key={`isometric_with_tabs_images-tab-${index}`}
                    eventKey={`tab-${index}`}
                    title={item.title.text}
                    className="h-100"
                  >
                    <Container>
                      <Row className="py-3 py-md-4 py-lg-5 position-relative">
                        <Col className="col-12 col-md-12 col-lg-12 col-xl-12 mt-md-5 mt-xl-0 pt-md-5 pt-xl-0 pt-0 pb-4 pb-md-0 px-0 px-xxl-5">
                          <GatsbyImage
                            className="mt-md-5 mt-lg-0"
                            image={item.image.gatsbyImageData}
                            alt={item.image.alt}
                          />
                        </Col>
                        <Col className="col-12 col-md-5 col-lg-4 col-xl-4 offset-st-1 pb-3 position-md-absolute start-0">
                          <div className="box-st-1">
                            <PrismicRichText
                              field={item.description.richText}
                              components={{
                                heading5: ({ children }) => (
                                  <h5 className="heading-st fs-st-10 pb-2">
                                    {children}
                                  </h5>
                                ),
                                paragraph: ({ children }) => (
                                  <p className="paragraph-st fs-st-11">
                                    {children}
                                  </p>
                                ),
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                ))}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyIsometricWithTabsImages on PrismicHomepageDataBodyIsometricWithTabsImages {
    id
    slice_type
    primary {
      style_number
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
    items {
      description {
        richText
        text
      }
      title {
        richText
        text
      }
      image {
        alt
        gatsbyImageData(placeholder: BLURRED)
        url
      }
    }
  }
`
