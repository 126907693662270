import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const InfoWithRightImageItems = ({ slice }) => {
  // console.log("InfoWithRightImageItems Slice: ", slice)

  return (
    <div
      className={`info_with_right_image_items style_number_${slice.primary.style_number}`}
    >
      {slice.primary.style_number === "0" ? null : (
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "bg-c-1 py-st-2 img-st-12"
              default:
                return ""
            }
          })()}
          style={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return {
                  backgroundImage: `url(${slice.primary.background_image.url})`,
                }
              default:
                return ""
            }
          })()}
        >
          <Container
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return "rounded-20"
                default:
                  return ""
              }
            })()}
          >
            <Row
              className={((main = `justify-content-center`) => {
                switch (slice.primary.style_number) {
                  case "1":
                    return `${main}`
                  default:
                    return ``
                }
              })()}
            >
              <Col
                className={((main = `col-12 col-md-9 col-lg-6`) => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return `${main} pt-xl-4 pt-xxl-3 mt-xxl-5`
                    default:
                      return ``
                  }
                })()}
              >
                <div className="pb-5">
                  <PrismicRichText
                    field={slice.primary.title.richText}
                    components={{
                      heading2: ({ children, key }) => (
                        <h2
                          key={key}
                          className={(() => {
                            switch (slice.primary.style_number) {
                              case "1":
                                return `heading-st fs-st-5 mb-2 char_length_${slice.primary.title.text.length}`
                              default:
                                return ""
                            }
                          })()}
                        >
                          {children}
                        </h2>
                      ),
                    }}
                  />
                  <PrismicRichText
                    field={slice.primary.subtitle.richText}
                    components={{
                      paragraph: ({ children, key }) => (
                        <h2
                          key={key}
                          className={(() => {
                            switch (slice.primary.style_number) {
                              case "1":
                                return `paragraph-st char_length_${slice.primary.title.text.length}`
                              default:
                                return ""
                            }
                          })()}
                        >
                          {children}
                        </h2>
                      ),
                    }}
                  />
                </div>
                <Row
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "row-cols-1 pe-4 pe-md-0"
                      default:
                        return ""
                    }
                  })()}
                >
                  {slice.items.map((item, index) => (
                    <Col
                      key={`info-with-right-image-items-${index}`}
                      className="item-st-1"
                    >
                      <PrismicRichText
                        field={item.title.richText}
                        components={{
                          heading3: ({ children, key }) => (
                            <h3
                              key={key}
                              className={(() => {
                                switch (slice.primary.style_number) {
                                  case "1":
                                    return `heading-st fs-st-9 pb-1`
                                  default:
                                    return ""
                                }
                              })()}
                            >
                              {children}
                            </h3>
                          ),
                        }}
                      />
                      <PrismicRichText
                        field={item.subtitle.richText}
                        components={{
                          paragraph: ({ children, key }) => (
                            <h2
                              key={key}
                              className={(() => {
                                switch (slice.primary.style_number) {
                                  case "1":
                                    return `paragraph-st char_length_${slice.primary.title.text.length}`
                                  default:
                                    return ""
                                }
                              })()}
                            >
                              {children}
                            </h2>
                          ),
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col
                className={((main = `col-12 col-md-9 col-lg-6`) => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return `${main} d-flex justify-content-center`
                    default:
                      return ""
                  }
                })()}
              >
                <GatsbyImage
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "filter-st-1"
                      default:
                        return ""
                    }
                  })()}
                  image={slice.primary.image.gatsbyImageData}
                  alt={slice.primary.image.alt}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithRightImageItems on PrismicPageDataBodyInfoWithRightImageItems {
    id
    slice_type
    primary {
      style_number
      title {
        richText
        text
      }
      subtitle {
        richText
        text
      }
      image {
        gatsbyImageData
        alt
        url
      }
      background_image {
        gatsbyImageData
        alt
        url(imgixParams: { auto: "", fit: "" })
      }
    }
    items {
      title {
        richText
        text
      }
      subtitle {
        richText
        text
      }
    }
  }
`
