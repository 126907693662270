import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const Statistics = ({ slice }) => {
  // console.log("Statistics Slice: ", slice)

  return (
    <div className={`statistics`}>
      <section className="bg-c-7 py-st-2">
        <Container className="">
          <Row className="justify-content-center">
            {slice.items.map((item, index) => (
              <Col
                key={`statistics-${index}`}
                className={`${
                  item.is_main_card ? "bg-c-13" : "bg-c-12"
                } d-flex flex-column justify-content-center align-items-center py-5 col-lg-6 col-md-9 col-12 filter-st-1`}
              >
                <PrismicRichText
                  field={item.title.richText}
                  components={{
                    heading3: ({ children }) => (
                      <h3 className="heading-st text-white fs-st-1 fw-bold mb-2">
                        {children}
                      </h3>
                    ),
                  }}
                />
                <PrismicRichText
                  field={item.subtitle.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="paragraph-st text-white px-sm-1 px-md-5 fw-bold mt-1 text-center fs-st-10">
                        {children}
                      </p>
                    ),
                  }}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyStatistics on PrismicPageDataBodyStatistics {
    id
    slice_type
    items {
      title {
        text
        richText
      }
      subtitle {
        text
        richText
      }
      is_main_card
    }
  }
`
