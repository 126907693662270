import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Card } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

export const DownloadsWithCardsImages = ({ slice }) => {
  // console.log("DownloadsWithCardsImages Slice: ", slice)

  return (
    <div
      className={`downloads_with_cards_images style_number_${slice.primary.style_number}`}
    >
      {slice.primary.style_number === "0" ? null : (
        <section className="bg-c-10 py-st-2">
          <Container>
            <PrismicRichText
              field={slice.primary.title.richText}
              components={{
                heading2: ({ children }) => (
                  <h2 className="heading-st fs-st-21 pb-2 pb-lg-3 text-center">
                    {children}
                  </h2>
                ),
              }}
            />
            <Row
              xs={2}
              md={3}
              lg={4}
              xl={slice.items.length}
              className="justify-content-center"
            >
              {slice.items.map((item, index) => (
                <Col
                  key={`downloads-with-cards-images-${index}`}
                  className="g-4"
                >
                  <PrismicLink field={item.link} className="a-st-1">
                    <Card className="h-100 shadow rounded-3">
                      <div className="m-auto">
                        <GatsbyImage
                          className="img-wrapper-st-1"
                          image={item.image.gatsbyImageData}
                          alt={item.image.alt}
                        />
                      </div>
                      <PrismicRichText
                        field={item.title.richText}
                        components={{
                          heading4: ({ children }) => (
                            <h2 className="heading-st mb-3 text-center">
                              {children}
                            </h2>
                          ),
                        }}
                      />
                    </Card>
                  </PrismicLink>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyDownloadsWithCardsImages on PrismicPageDataBodyDownloadsWithCardsImages {
    id
    slice_type
    primary {
      style_number
      title {
        richText
        text
      }
    }
    items {
      link {
        url
        target
        link_type
      }
      image {
        gatsbyImageData
        alt
        url
      }
      title {
        richText
        text
      }
    }
  }
`
