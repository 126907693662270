import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { idMaker } from "../utils/idMaker"

export const CtaWithRightImageLinks = ({ slice }) => {
  // console.log("CtaWithRightImage Slice: ", slice)

  return (
    <div
      className={`cta_with_right_image_links style_number_${slice.primary.style_number}`}
      id={idMaker(slice.primary.title.text)}
    >
      {slice.primary.style_number === "0" ? null : (
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "bg-c-7 py-st-2"
              case "2":
                return "bg-c-7 py-st-2"
              case "3":
                return "bg-c-7 py-st-2"
              default:
                return ""
            }
          })()}
        >
          <Container
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return "bg-st-1 rounded-st-1"
                case "2":
                  return ""
                case "3":
                  return ""
                default:
                  return ""
              }
            })()}
          >
            <Row className="justify-content-center align-items-center">
              <Col
                className={(() => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return "col-12 col-md-12 col-lg-8"
                    case "2":
                      return "col-12 col-md-9 col-lg-6 m-auto py-2 order-last order-lg-first"
                    case "3":
                      return "col-12 col-md-9 col-lg-6 m-auto z-index-3 order-last order-lg-first"
                    default:
                      return ""
                  }
                })()}
              >
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading1: ({ children }) => (
                      <h1
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "heading-st pt-5 pb-4 pb-md-4 pb-lg-5 text-center text-white"
                            case "2":
                              return "heading-st fs-st-6 text-lg-start py-3"
                            case "3":
                              return "heading-st fs-st-6 py-3 mt-lg-5 mt-xl-0"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </h1>
                    ),
                    heading2: ({ children }) => (
                      <h2
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "heading-st fs-st-6 my-3 my-lg-5 text-center text-white"
                            case "2":
                              return "heading-st fs-st-6 text-lg-start"
                            case "3":
                              return "heading-st fs-st-6 my-3"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </h2>
                    ),
                  }}
                />
                <PrismicRichText
                  field={slice.primary.subtitle.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "paragraph-st fs-st-15 mb-lg-4 px-xl-1 px-xxl-4 text-white text-center"
                            case "2":
                              return "paragraph-st fs-st-10 mb-2 mb-lg-3 mb-xl-4"
                            case "3":
                              return "paragraph-st fs-st-10 pb-1 pb-md-2 pb-lg-3"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </p>
                    ),
                  }}
                />
                <div
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "text-center"
                      case "2":
                        return "text-center text-lg-start"
                      case "3":
                        return "text-center text-lg-start"
                      default:
                        return ""
                    }
                  })()}
                >
                  <Row
                    xs={1}
                    sm={2}
                    lg={slice.items.length % 2 === 0 ? 2 : 3}
                    className="justify-content-evenly"
                  >
                    {slice.items.map((item, index) => (
                      <Col key={`cta-with-right-image-links-${index}`}>
                        <PrismicLink
                          field={item.button_link}
                          className={(() => {
                            switch (slice.primary.style_number) {
                              case "1":
                                return "btn-st"
                              case "2":
                                return "btn-st text-center"
                              case "3":
                                return "btn-st"
                              default:
                                return ""
                            }
                          })()}
                        >
                          {item.button_label}
                        </PrismicLink>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col
                className={(() => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return "col-12 col-md-12 col-lg-4 text-center text-lg-start ps-lg-4 ps-xxl-0 pe-lg-5 position-lg-relative h-lg-500px"
                    case "2":
                      return "col-12 col-md-9 col-lg-6 px-0 order-first order-lg-last filter-st-1"
                    case "3":
                      return "col-12 col-md-9 col-lg-6 position-relative px-0 m-auto order-first order-lg-last d-flex justify-content-lg-end justify-content-center"
                    default:
                      return ""
                  }
                })()}
              >
                <div
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return ""
                      case "2":
                        return "clip-st-1"
                      case "3":
                        return "img-wrapper-st-6"
                      default:
                        return ""
                    }
                  })()}
                >
                  <GatsbyImage
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "position-lg-absolute img-st-13"
                        case "2":
                          return "rounded-st-3"
                        case "3":
                          return "img-st-4 rounded-st-3"
                        default:
                          return ""
                      }
                    })()}
                    image={slice.primary.image.gatsbyImageData}
                    alt={slice.primary.image.alt}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCtaWithRightImageLinks on PrismicPageDataBodyCtaWithRightImageLinks {
    id
    slice_type
    primary {
      style_number
      image {
        gatsbyImageData
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
    items {
      button_label
      button_link {
        url
        target
        link_type
      }
    }
  }
`
