import * as React from "react"
import { Link, graphql } from "gatsby"
import { Container, Row, Col, Badge } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { OtherBlogCards } from "../components/OtherBlogCards"
import Seo from "../components/Seo"
// import { useScrollRestoration } from "gatsby"

const BlogTemplate = ({ data }) => {
  // const scrollRestoration = useScrollRestoration(`blog-template-scroll`)
  if (!data) return null

  const footer = data.prismicFooter || {}
  const navbar = data.prismicNavbar || {}
  const blogContent = data.prismicBlog
  const blog = blogContent.data || {}

  const { lang, type, url } = blogContent
  const alternateLanguages = blogContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout navbar={navbar.data} activeDocMeta={activeDoc} footer={footer.data}>
      <div
        className={`blog`}
        // {...scrollRestoration}
      >
        <section className="bg-c-7 py-st-2">
          <Container className="mb-2">
            <Row className="justify-content-center">
              <Col className="col-lg-8 bg-white px-4 py-3 mb-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb-st-1">
                    <li className="breadcrumb-item-st-1">
                      <Link to="/">Home</Link>
                      <span className="text-6c757d">&nbsp;/&nbsp;</span>
                    </li>
                    <li className="breadcrumb-item-st-1">
                      <Link to="/blogs">Blogs and Articles</Link>
                      <span className="text-6c757d">&nbsp;/&nbsp;</span>
                    </li>
                    <li className="breadcrumb-item-st-1 breadcrumb-item-active-st-1">
                      <div>{blog.title.text}</div>
                    </li>
                  </ol>
                </nav>
              </Col>
            </Row>
          </Container>
          <Container className="">
            <Row className="justify-content-center">
              <Col className="col-lg-8 bg-white rounded-3 p-st-3">
                <div className="d-flex justify-content-end">
                  <Badge bg="success">{blog.article_category}</Badge>
                </div>
                <PrismicRichText
                  field={blog.title.richText}
                  components={{
                    heading1: ({ children }) => (
                      <h1 className="heading-st fs-st-5 text-center py-3 text-0f3855">
                        {children}
                      </h1>
                    ),
                  }}
                />
                <PrismicRichText
                  field={blog.abstract.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="paragraph-st fs-st-11 text-center text-0f3855">
                        {children}
                      </p>
                    ),
                  }}
                />
                <div className="d-flex justify-content-center justify-content-md-start py-3">
                  <div className="pe-2">
                    {blog.author_image.gatsbyImageData ? (
                      <GatsbyImage
                        className="img-wrapper-st-3"
                        image={blog.author_image.gatsbyImageData}
                        alt={blog.author_image.alt}
                      />
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-center flex-column">
                    <PrismicRichText
                      field={blog.author_name.richText}
                      components={{
                        heading6: ({ children }) => (
                          <h6 className="heading-st">{children}</h6>
                        ),
                      }}
                    />
                    <div className="text-828585">{blog.date}</div>
                  </div>
                </div>
                <GatsbyImage
                  className="img-wrapper-st-4 filter-st-1"
                  image={blog.main_image.gatsbyImageData}
                  alt={blog.main_image.alt}
                />

                <PrismicRichText
                  field={blog.description.richText}
                  components={{
                    paragraph: ({ children, key }) => (
                      <p className="paragraph-st fs-st-11 py-2 lh-md" key={key}>
                        {children}
                      </p>
                    ),
                    heading2: ({ children, key }) => (
                      <h2
                        className="heading-st fs-st-9 pb-2 pt-4 text-0f3855"
                        key={key}
                      >
                        {children}
                      </h2>
                    ),
                    heading3: ({ children, key }) => (
                      <h3
                        className="heading-st fs-st-10 pb-2 pt-3 text-0f3855"
                        key={key}
                      >
                        {children}
                      </h3>
                    ),
                    heading4: ({ children, key }) => (
                      <h4
                        className="heading-st fs-st-10 pb-2 pt-3 text-0f3855"
                        key={key}
                      >
                        {children}
                      </h4>
                    ),
                    image: ({ node, key }) => {
                      return (
                        <div
                          key={key}
                          className="block-img d-flex align-items-start justify-content-center p-lg-4 my-3"
                        >
                          <img
                            className="img-fluid"
                            src={node.url}
                            alt={node.alt ?? undefined}
                            data-copyright={
                              node.copyright ? node.copyright : undefined
                            }
                            loading="lazy"
                          />
                        </div>
                      )
                    },
                    oList: ({ children }) => (
                      <ol className="ol-st-2">{children}</ol>
                    ),
                    oListItem: ({ children, key }) => (
                      <li className="li-st-2 paragraph-st fs-st-11" key={key}>
                        {children}
                      </li>
                    ),
                    list: ({ children }) => (
                      <ul className="ul-st-1">{children}</ul>
                    ),
                    listItem: ({ children, key }) => (
                      <li className="li-st-1 paragraph-st fs-st-11" key={key}>
                        {children}
                      </li>
                    ),
                  }}
                />
              </Col>
            </Row>
          </Container>
          {blog.other_resources.length > 0 && (
            <OtherBlogCards otherResources={blog.other_resources} />
          )}
        </section>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  return (
    <Seo
      title={data.prismicBlog.data.meta_title}
      description={data.prismicBlog.data.meta_description}
      keywords={data.prismicBlog.data.meta_keywords}
      author={data.prismicBlog.data.author_name.text}
    />
  )
}

export const query = graphql`
  query blogQuery($id: String, $lang: String) {
    prismicBlog(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      tags
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        display_title
        meta_title
        meta_description
        meta_keywords {
          keyword
        }
        title {
          richText
          text
        }
        abstract {
          richText
          text
        }
        article_category
        date(formatString: "DD-MM-YYYY")
        main_image {
          alt
          url
          gatsbyImageData
        }
        author_name {
          richText
          text
        }
        author_image {
          alt
          url
          gatsbyImageData
        }
        description {
          text
          richText
        }
        other_resources {
          resource_link {
            url
          }
        }
      }
    }
    prismicNavbar(lang: { eq: $lang }) {
      ...NavbarFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(BlogTemplate)
