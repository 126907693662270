import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const InfoWithRightImage = ({ slice }) => {
  return (
    <div className={`info_with_right_image style_number_${slice.primary.style_number}`}>
      {slice.primary.style_number === "0" ? null : (
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "bg-c-7 py-st-2"
              case "2":
                return "bg-c-7 py-st-2"
              case "3":
                return "bg-c-7 py-2"
              case "4":
                return "bg-c-7 py-st-2"
              case "5":
                return "bg-c-7 py-st-2"
              case "6":
                return "bg-c-7 py-st-2"
              default:
                return ""
            }
          })()}
        >
          <Container
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return ""
                case "2":
                  return ""
                case "3":
                  return "bg-white rounded-20 p-3"
                case "4":
                  return ""
                case "5":
                  return ""
                case "6":
                  return ""
                default:
                  return ""
              }
            })()}
          >
            <Row className="justify-content-center">
              <Col
                className={((
                  main = `col-12 col-md-9 col-lg-6 d-flex flex-column justify-content-center`
                ) => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return `${main} bg-c-9 p-5 rounded-st-4-t rounded-st-6-l filter-st-1`
                    case "2":
                      return `${main} order-last order-lg-first py-2`
                    case "3":
                      return `${main} ps-lg-5`
                    case "4":
                      return `${main} bg-c-6 border-st-5 p-5 rounded-st-4-t rounded-st-6-l`
                    case "5":
                      return `${main} order-last order-lg-first py-2`
                    case "6":
                      return `${main} order-last order-lg-first py-2`
                    default:
                      return ``
                  }
                })()}
              >
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading2: ({ children }) => (
                      <div className="heading-container">
                        <h2
                          className={(() => {
                            switch (slice.primary.style_number) {
                              case "1":
                                return "heading-st fs-st-2 text-white text-center pb-2";
                              case "2":
                                return "heading-st fs-st-7 py-3";
                              case "3":
                                return "heading-st fs-st-7 py-3";
                              case "4":
                                return "heading-st fs-st-7 text-white text-center pb-2";
                              case "5":
                                return "heading-st fs-st-7 py-3";
                              case "6":
                                return "heading-st fs-st-7 py-3";
                              default:
                                return "";
                            }
                          })()}
                        >
                          {children}
                        </h2>

                        <svg
                          className="scribble-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 150"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9"
                            fill="none"
                            stroke="#0a99ff"
                            strokeWidth="8"
                            strokeLinecap="square"
                            strokeLinejoin="square"
                          >
                            <animate
                              attributeName="stroke-dasharray"
                              from="0, 1500"
                              to="1500, 0"
                              dur="2s"
                              repeatCount="1"
                              keyTimes="0; 1"
                              keySplines="0.4, 0, 0.2, 1"
                              calcMode="spline"
                            />
                          </path>
                        </svg>
                      </div>

                    ),
                  }}
                />

                <PrismicRichText
                  field={slice.primary.subtitle.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "paragraph-st fs-st-10 text-white text-center"
                            case "2":
                              return "paragraph-st fs-st-11 pb-3"
                            case "3":
                              return "paragraph-st fs-st-11 pb-3"
                            case "4":
                              return "paragraph-st fs-st-11 text-white text-center"
                            case "5":
                              return "paragraph-st fs-st-11 pb-3"
                            case "6":
                              return "paragraph-st fs-st-11 pb-3"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </p>
                    ),
                  }}
                />
              </Col>
              <Col
                className={((
                  main = `col-12 col-md-9 col-lg-6 d-flex flex-column justify-content-center`
                ) => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return `${main} px-0 rounded-st-5-t rounded-st-7-r overflow-hidden filter-st-1`
                    case "2":
                      return `${main} m-auto px-0 order-first order-lg-last filter-st-1`
                    case "3":
                      return `${main} m-auto px-lg-5 text-center`
                    case "4":
                      return `${main} border-st-4 px-0 rounded-st-5-t rounded-st-7-r`
                    case "5":
                      return `${main} m-auto px-0 order-first order-lg-last filter-st-1`
                    case "6":
                      return `${main} m-auto px-0 order-first order-lg-last`
                    default:
                      return ""
                  }
                })()}
              >
                <GatsbyImage
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "w-100"
                      case "2":
                        return "clip-st-5 rounded-st-3"
                      case "3":
                        return "rounded-10 img-st-6"
                      case "4":
                        return "w-100 rounded-st-5-t rounded-st-9 bg-c-16"
                      case "5":
                        return "clip-st-5 rounded-st-3 bg-white"
                      case "6":
                        return "bg-white img-st-6-a"
                      default:
                        return ""
                    }
                  })()}
                  image={slice.primary.image.gatsbyImageData}
                  alt={slice.primary.image.alt}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )
      }
    </div >
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithRightImage on PrismicPageDataBodyInfoWithRightImage {
    id
    slice_type
    primary {
      style_number
      image {
        gatsbyImageData(imgixParams: { auto: "", fit: "" })
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }
`
