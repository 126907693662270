import React from "react";
import { graphql, Link } from "gatsby";
import { Container, Row, Col, Card } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { idMaker } from "../utils/idMaker";

// Utility function to get class names based on style number
const getClassNames = (styleNumber, type) => {
  const classMappings = {
    section: {
      1: "bg-c-7 py-st-2",
      2: "bg-c-10 py-st-2",
      3: "bg-c-11 py-st-2",
      4: "bg-c-7 py-st-2",
    },
    card: {
      1: "card-st-1",
      2: "card-st-2 h-100",
      3: "border-0 shadow-none h-100 bg-transparent text-center",
      4: "border-0 shadow-none h-100 bg-transparent text-center",
    },
    imageWrapper: {
      1: "m-auto img-wrapper-st-5 filter-st-1",
      2: "mt-5 mx-5 px-5 px-lg-1 px-st-4",
    },
    image: {
      1: "",
      2: "filter-st-2",
      3: "img-st-17 filter-st-2",
      4: "img-st-7 filter-st-2",
    },
    cardBody: {
      1: "",
      2: "card-st-2-body",
      3: "my-2",
      4: "my-2",
    },
    title: {
      1: "heading-st fs-st-7",
      2: "heading-st fs-st-14",
      3: "heading-st fs-st-14",
      4: "heading-st fs-st-14",
    },
    subtitle: {
      1: "paragraph-st pt-1 pt-md-2 pt-lg-3 fs-st-11",
      2: "paragraph-st pt-1 pt-md-2 pt-lg-3 fs-st-11 mt-4",
      3: "paragraph-st pt-1 pt-md-2 pt-lg-3 fs-st-11",
      4: "paragraph-st pt-1 pt-md-2 pt-lg-3 fs-st-11",
    },
  };
  return classMappings[type][styleNumber] || "";
};

// Subcomponents for repeated elements
const RichTextTitle = ({ field, styleNumber }) => (
  <PrismicRichText
    field={field}
    components={{
      heading2: ({ children }) => (
        <h2 className={getClassNames(styleNumber, "title")}>{children}</h2>
      ),
    }}
  />
);

const RichTextSubtitle = ({ field, styleNumber }) => (
  <PrismicRichText
    field={field}
    components={{
      paragraph: ({ children }) => (
        <p className={getClassNames(styleNumber, "subtitle")}>{children}</p>
      ),
    }}
  />
);

const FeatureCard = ({ item, styleNumber }) => {
  const cardContent = (
    <Card className={getClassNames(styleNumber, "card")}>
      <div className={getClassNames(styleNumber, "imageWrapper")}>
        <GatsbyImage
          className={getClassNames(styleNumber, "image")}
          image={item.image.gatsbyImageData}
          alt={item.image.alt}
        />
      </div>
      <Card.Body className={getClassNames(styleNumber, "cardBody")}>
        <PrismicRichText
          field={item.title.richText}
          components={{
            heading4: ({ children }) => (
              <h4 className="card-st-2-title fs-st-12">{children}</h4>
            ),
          }}
        />
        <PrismicRichText
          field={item.subtitle.richText}
          components={{
            paragraph: ({ children }) => (
              <p className="card-st-2-text">{children}</p>
            ),
          }}
        />
      </Card.Body>
    </Card>
  );

  // Wrap the card content with an anchor if a URL is provided
  if (item.link && item.link.url) {
    return (
      <Col className={item.is_main_card ? "mt-lg-1" : ""}>
        <a href={item.link.url} target={item.link.target} rel="noopener noreferrer">
          {cardContent}
        </a>
      </Col>
    );
  }

  return (
    <Col className={item.is_main_card ? "mt-lg-1" : ""}>{cardContent}</Col>
  );
};

export const FeaturesWithCardsImages = ({ slice }) => {
  const { primary, items } = slice;
  const { style_number, title, subtitle } = primary;

  return (
    <div
      className={`features_with_cards_images style_number_${style_number}`}
      id={idMaker(title.text)}
    >
      {style_number !== "0" && (
        <section className={getClassNames(style_number, "section")}>
          <Container>
            {(title.richText.length > 0 || subtitle.richText.length > 0) && (
              <Row className="pb-3 pb-md-4 pb-lg-5">
                <Col className="text-center">
                  <RichTextTitle field={title.richText} styleNumber={style_number} />
                  <RichTextSubtitle field={subtitle.richText} styleNumber={style_number} />
                </Col>
              </Row>
            )}
            <Row
              xs={1}
              sm={2}
              lg={items.length % 2 === 0 ? 4 : 3}
              className="g-4 justify-content-center"
            >
              {items.map((item, index) => (
                <FeatureCard key={`features-with-cards-${index}`} item={item} styleNumber={style_number} />
              ))}
            </Row>
          </Container>
        </section>
      )}
    </div>
  );
};

// GraphQL Fragments
export const query = graphql`
  fragment HomepageDataBodyFeaturesWithCardsImages on PrismicHomepageDataBodyFeaturesWithCardsImages {
    id
    slice_type
    items {
      image {
        alt
        url
        gatsbyImageData
      }
      is_main_card
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
      link {
        url
        target
      }
    }
    primary {
      style_number
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
  }

  fragment PageDataBodyFeaturesWithCardsImages on PrismicPageDataBodyFeaturesWithCardsImages {
    id
    slice_type
    items {
      image {
        alt
        url
        gatsbyImageData
      }
      is_main_card
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
      link {
        url
        target
      }
    }
    primary {
      style_number
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
  }
`;
