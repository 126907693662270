import { ArticleWithImage } from "./articleWithImage"
import { BackgroundVideo } from "./backgroundVideo"
import { Banner } from "./banner"
import { CtaWithCardsDoubleLinks } from "./ctaWithCardsDoubleLinks"
import { CtaWithCardsImages } from "./ctaWithCardsImages"
import { CtaWithFullWidthImage } from "./ctaWithFullWidthImage"
import { CtaWithLeftImage } from "./ctaWithLeftImage"
import { CtaWithRightImage } from "./ctaWithRightImage"
import { CtaWithRightImageLinks } from "./ctaWithRightImageLinks"
import { DemoWithTabsImages } from "./demoWithTabsImages"
import { DownloadsWithCardsImages } from "./downloadsWithCardsImages"
import { EmbeddedFormWithInfo } from "./embeddedFormWithInfo"
import { FAQ } from "./faq"
import { FeaturesWithCardsImages } from "./featuresWithCardsImages"
import { Headline } from "./headline"
import { Hero } from "./hero"
import { InfoWithBackgroundImage } from "./infoWithBackgroundImage"
import { InfoWithFullWidthImage } from "./infoWithFullWidthImage"
import { InfoWithFullWidthVideo } from "./infoWithFullWidthVideo"
import { InfoWithLeftImage } from "./infoWithLeftImage"
import { InfoWithRightImage } from "./infoWithRightImage"
import { InfoWithRightImageItems } from "./infoWithRightImageItems"
import { InfoWithRightImageSides } from "./infoWithRightImageSides"
import { IsometricWithTabsImages } from "./isometricWithTabsImages"
import { JoinUs } from "./joinUs"
import { PartnersWithCardsImages } from "./partnersWithCardsImages"
import { Posts } from "./posts"
import { RedirectWithCardsImages } from "./redirectWithCardsImages"
import { Services } from "./services"
import { Statistics } from "./statistics"
import { StepsWithCardsImages } from "./stepsWithCardsImages"
import { Testimonials } from "./testimonials"
import { TestSlice } from "./testSlice"
import { WrittenByWithImage } from "./writtenByWithImage"
import { Pricing } from "./pricing"
import { TwoColumnContent } from "./twoColumnContent"

export const components = {
  article_with_image: ArticleWithImage,
  background_video: BackgroundVideo,
  banner: Banner,
  cta_with_cards_double_links: CtaWithCardsDoubleLinks,
  cta_with_cards_images: CtaWithCardsImages,
  cta_with_full_width_image: CtaWithFullWidthImage,
  cta_with_left_image: CtaWithLeftImage,
  cta_with_right_image: CtaWithRightImage,
  cta_with_right_image_links: CtaWithRightImageLinks,
  demo_with_tabs_images: DemoWithTabsImages,
  downloads_with_cards_images: DownloadsWithCardsImages,
  embedded_form_with_info: EmbeddedFormWithInfo,
  faq: FAQ,
  features_with_cards_images: FeaturesWithCardsImages,
  headline: Headline,
  hero: Hero,
  info_with_background_image: InfoWithBackgroundImage,
  info_with_full_width_image: InfoWithFullWidthImage,
  info_with_full_width_video: InfoWithFullWidthVideo,
  info_with_left_image: InfoWithLeftImage,
  info_with_right_image: InfoWithRightImage,
  info_with_right_image_items: InfoWithRightImageItems,
  info_with_right_image_sides: InfoWithRightImageSides,
  isometric_with_tabs_images: IsometricWithTabsImages,
  join_us: JoinUs,
  partners_with_cards_images: PartnersWithCardsImages,
  posts: Posts,
  redirect_with_cards_images: RedirectWithCardsImages,
  services: Services,
  statistics: Statistics,
  steps_with_cards_images: StepsWithCardsImages,
  testimonials: Testimonials,
  test_slice: TestSlice,
  written_by_with_image: WrittenByWithImage,
  pricing: Pricing,
  two_column_content: TwoColumnContent,
}
