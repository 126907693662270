import React from "react"
import { graphql } from "gatsby"
import { PrismicLink, PrismicRichText } from "@prismicio/react"
import { Col, Container, Row } from "react-bootstrap"
import { idMaker } from "../utils/idMaker"

export const JoinUs = ({ slice }) => {
  // console.log("JoinUs Slice: ", slice)

  return (
    <div className={`join_us`} id={idMaker(slice.primary.title.text)}>
      <section className="bg-c-7 py-st-2">
        <Container>
          <Row>
            <Col className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 bg-white rounded-st-1 shadow py-4 py-lg-5">
              <PrismicRichText
                field={slice.primary.title.richText}
                components={{
                  heading5: ({ children }) => (
                    <h5 className="heading-st fs-st-7 text-center mb-3 px-2 px-md-3 px-lg-5">
                      {children}
                    </h5>
                  ),
                }}
              />
              <div className="d-flex flex-column justify-content-center">
                <PrismicRichText
                  field={slice.primary.subtitle.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="paragraph-st fs-st-11 text-md-center">
                        {children}
                      </p>
                    ),
                    list: ({ children }) => (
                      <ul className="ul-st-1 d-md-flex justify-content-center flex-wrap mt-2">
                        {children}
                      </ul>
                    ),
                    listItem: ({ children }) => (
                      <li className="li-st-1 paragraph-st fs-st-11 mx-4">
                        {children}
                      </li>
                    ),
                  }}
                />
              </div>
              <div className="d-flex justify-content-center">
                <PrismicLink
                  field={slice.primary.button_link}
                  className="btn-st my-4"
                >
                  {slice.primary.button_label}
                </PrismicLink>
              </div>
              <PrismicRichText
                field={slice.primary.call_us.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p className="paragraph-st fs-st-11 mt-1 text-center text-828585">
                      {children}
                    </p>
                  ),
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyJoinUs on PrismicPageDataBodyJoinUs {
    id
    slice_type
    primary {
      title {
        richText
        text
      }
      subtitle {
        richText
        text
      }
      button_label
      button_link {
        url
        target
        link_type
      }
      call_us {
        text
        richText
      }
    }
  }

  fragment HomepageDataBodyJoinUs on PrismicHomepageDataBodyJoinUs {
    id
    slice_type
    primary {
      title {
        richText
        text
      }
      subtitle {
        richText
        text
      }
      button_label
      button_link {
        url
        target
        link_type
      }
      call_us {
        text
        richText
      }
    }
  }
`
