import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"

export const Banner = ({ slice }) => {
  // console.log("Banner Slice: ", slice)

  return slice.primary.style_number === "0" ? null : (
    <>
      <div className="bg-c-7 py-st-1"></div>
      <div className={`banner style_number_${slice.primary.style_number}`}>
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "py-st-1"
              case "2":
                return "bg-st-5 py-3 py-md-5"
              default:
                return ""
            }
          })()}
          style={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return null
              case "2":
                return {
                  backgroundImage: `url(${slice.primary.background_image.url})`,
                }
              default:
                return null
            }
          })()}
        >
          <Container
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return "py-3 py-md-4 rounded-st-8 bg-st-3"
                case "2":
                  return ""
                default:
                  return ""
              }
            })()}
            style={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return {
                    backgroundImage: `url(${slice.primary.background_image.url})`,
                  }
                case "2":
                  return null
                default:
                  return null
              }
            })()}
            // style={{
            //   backgroundImage: `url(${slice.primary.background_image.url})`,
            // }}
          >
            <Row className="flex-row align-items-center">
              <Col className="h-100 col-12 col-md-9 col-lg-9 d-flex justify-content-center justify-content-md-center justify-content-lg-center align-items-center">
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading2: ({ children }) => (
                      <h2 className="heading-st fs-st-6 text-white py-2 my-2 mt-md-1 text-center">
                        {children}
                      </h2>
                    ),
                  }}
                />
              </Col>
              <Col className="h-100 col-12 col-md-3 col-lg-3 d-flex justify-content-center justify-content-md-center justify-content-lg-center align-items-center">
                <div className="">
                  <PrismicLink
                    field={slice.primary.button_link}
                    className="btn-st btn-st-3"
                  >
                    {slice.primary.button_label}
                  </PrismicLink>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {/* <div className="bg-c-7 py-st-1"></div> */}
    </>
  )
}

export const query = graphql`
  fragment HomepageDataBodyBanner on PrismicHomepageDataBodyBanner {
    id
    primary {
      style_number
      button_label
      button_link {
        url
        target
        link_type
      }
      background_image {
        url(imgixParams: { auto: "", fit: "" })
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }

  fragment PageDataBodyBanner on PrismicPageDataBodyBanner {
    id
    primary {
      style_number
      button_label
      button_link {
        url
        target
        link_type
      }
      background_image {
        url(imgixParams: { auto: "", fit: "" })
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }
`
