import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { Container, Row, Col } from "react-bootstrap"

export const Pricing = ({ slice }) => {
    if (!slice || !slice.items) {
        console.log("Slice or items missing"); // Debugging if slice is undefined
        return null;
    }

    return (
        <section className="bsb-pricing-3 py-5 py-xl-8">
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={10} lg={8} xl={7}>
                        <h1 className="fs-5 mb-2 text-secondary text-center text-uppercase">
                            {slice.primary.titles?.text || "Default Title"}
                        </h1>
                        <h5 className="display-5 mb-5 mb-xl-9 text-center">
                            {slice.primary.titles_description?.text || "Default Title"}
                        </h5>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    {slice.items.map((item, index) => (
                        <Col key={index} xs={12} md={4}>
                            <div className={`card ${index === 1 ? 'bg-white border-0 border-top pt-md-6 shadow-sm bsb-pricing-popular' : 'border-1 bg-white'} mb-4`}>
                                <div className="card-body p-4 p-xxl-5 position-relative">
                                    {item.is_the_main && (
                                        <span className="badge bg-primary position-absolute top-0 start-50 translate-middle">Most Popular</span>
                                    )}
                                    <h2 className="h4 mb-2">{item.card_title?.text || "Default Title"}</h2>

                                    {/* Corrected rich text rendering */}
                                    {item.card_description?.richText ? (
                                        <PrismicRichText field={item.card_description.richText} />
                                    ) : (
                                        <small className="text-muted">Default Description</small>
                                    )}

                                    <hr className="my-4 border-dark-subtle" />
                                    <h4 className="display-3 fw-bold text-primary m-0">{item.card_price?.text || "$0"}</h4>
                                    <p className="text-secondary m-0">£ / Week</p>
                                    <small className="text-muted">
                                        {item.price_condition?.richText ? (
                                            <PrismicRichText field={item.price_condition.richText} />
                                        ) : (
                                            "Default Description"
                                        )}
                                    </small>

                                    <div className="d-grid gap-2 my-4">
                                        <a href={item.card_url?.url || "#"} className={`btn btn-lg ${index === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                                            {item.card_button_text?.text || "Choose Plan"}
                                        </a>
                                    </div>
                                    <small className="text-muted">
                                        {item.terms?.richText ? (
                                            <PrismicRichText field={item.terms.richText} />
                                        ) : (
                                            "Default Description"
                                        )}
                                    </small>

                                    <hr className="my-4 border-dark-subtle" />
                                    <h6 className="text-muted">
                                        {item.extras?.richText ? (
                                            <PrismicRichText field={item.extras.richText} />
                                        ) : (
                                            "Default Description"
                                        )}
                                    </h6>

                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export const query = graphql`
  fragment PageDataBodyPricing on PrismicPageDataBodyPricing {
    id
    slice_type
    primary {
      titles {
        richText
        text
      }
      titles_description {
        richText
        text
      }
    }
    items {
      card_title {
        richText
        text
      }
      card_description {
        richText
        text
      }
      card_price {
        richText
        text
      }
      price_condition {
        richText
        text
      }
      is_the_main
      card_url {
        url
      }
      card_button_text {
        richText
        text
      }
        terms {
        richText
        text
      }
        extras {
        richText
        text
      }
    }
  }
`;
