import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

export const WrittenByWithImage = ({ slice }) => {
  // console.log("WrittenByWithImage Slice: ", slice)

  return (
    <div className={`written_by_with_image`}>
      <section className="bg-c-7">
        <Container className="">
          <Row>
            <Col className="col-lg-8 offset-lg-2">
              <Row className="">
                <Col className="col-lg-1 d-flex justify-content-end align-items-center">
                  <GatsbyImage
                    className=""
                    image={slice.primary.image.gatsbyImageData}
                    alt={slice.primary.image.alt}
                  />
                </Col>
                <Col className="col-10 d-flex flex-column justify-content-start ps-0 mt-1">
                  <PrismicRichText
                    field={slice.primary.written_by.richText}
                    components={{
                      paragraph: ({ children }) => (
                        <p className="paragraph-st p-0 m-0">{children}</p>
                      ),
                    }}
                  />
                  <PrismicRichText
                    field={slice.primary.updated_date.richText}
                    components={{
                      paragraph: ({ children }) => (
                        <p className="paragraph-st p-0 m-0">{children}</p>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyWrittenByWithImage on PrismicPageDataBodyWrittenByWithImage {
    id
    slice_type
    primary {
      image {
        gatsbyImageData
        alt
      }
      updated_date {
        text
        richText
      }
      written_by {
        text
        richText
      }
    }
  }
`
