import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Card } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

export const RedirectWithCardsImages = ({ slice }) => {
  // console.log("RedirectWithCardsImages Slice: ", slice)

  return (
    <div
      className={`redirect_with_cards_images style_number_${slice.primary.style_number}`}
    >
      {slice.primary.style_number === "0" ? null : (
        <section className="bg-c-7 py-st-2">
          <Container>
            <PrismicRichText
              field={slice.primary.title.richText}
              components={{
                heading2: ({ children }) => (
                  <h2 className="heading-st fs-st-20 pb-3 pb-md-4 pb-lg-5 text-center">
                    {children}
                  </h2>
                ),
              }}
            />
            <Row xs={1} sm={2} lg={3} className="g-4 justify-content-center">
              {slice.items.map((item, index) => (
                <Col key={`redirect-with-cards-images-${index}`} className="">
                  <Card
                    className={(() => {
                      switch (slice.primary.style_number) {
                        case "1":
                          return "h-100 shadow rounded-3"
                        case "2":
                          return "h-100 shadow rounded-3"
                        default:
                          return ""
                      }
                    })()}
                  >
                    <div
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return ""
                          case "2":
                            return "m-auto mt-5 img-st-17"
                          default:
                            return ""
                        }
                      })()}
                    >
                      <GatsbyImage
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return ""
                            case "2":
                              return "filter-st-2"
                            default:
                              return ""
                          }
                        })()}
                        image={item.image.gatsbyImageData}
                        alt={item.image.alt}
                      />
                    </div>
                    <Card.Body
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "pt-2 pt-md-3 pb-1"
                          case "2":
                            return ""
                          default:
                            return ""
                        }
                      })()}
                    >
                      <PrismicRichText
                        field={item.title.richText}
                        components={{
                          heading3: ({ children }) => (
                            <h3 className="card-st-2-title fs-st-12 mt-0">
                              {children}
                            </h3>
                          ),
                        }}
                      />
                      <PrismicRichText
                        field={item.subtitle.richText}
                        components={{
                          paragraph: ({ children }) => (
                            <p className="card-st-2-text">{children}</p>
                          ),
                        }}
                      />
                    </Card.Body>
                    <div className="d-flex justify-content-center mb-3">
                      <PrismicLink
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "btn-st btn-st-2"
                            case "2":
                              return "btn-st"
                            default:
                              return ""
                          }
                        })()}
                        field={item.button_link}
                      >
                        {item.button_label}
                      </PrismicLink>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment HomepageBodyRedirectWithCardsImages on PrismicHomepageDataBodyRedirectWithCardsImages {
    id
    slice_type
    primary {
      style_number
      title {
        richText
        text
      }
    }
    items {
      button_label
      button_link {
        url
        target
        link_type
      }
      image {
        gatsbyImageData
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }

  fragment PageDataBodyRedirectWithCardsImages on PrismicPageDataBodyRedirectWithCardsImages {
    id
    slice_type
    primary {
      style_number
      title {
        richText
        text
      }
    }
    items {
      button_label
      button_link {
        url
        target
        link_type
      }
      image {
        gatsbyImageData
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }
`
