import * as React from "react"

import { Navbar } from "./Navbar"
import { Footer } from "./Footer"
// import { useScrollRestoration } from "gatsby"

const Layout = ({ children, navbar, footer, activeDocMeta }) => {
  // const scrollRestoration = useScrollRestoration(`layout-scroll`)

  return (
    <>
      <Navbar navbar={navbar} activeDocMeta={activeDocMeta} />
      <main className="main-st"
        // {...scrollRestoration}
      >
        {children}
      </main>
      <Footer footer={footer} />
    </>
  )
}

export default Layout
