import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Card, Badge } from "react-bootstrap"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

export const OtherBlogCards = ({ otherResources }) => {
  const title1 = "Did you like this article?"
  const title2 = "Here are some other resources you might like"

  const allBlogCardData = useStaticQuery(graphql`
    query allBlogCardDataQuery {
      allPrismicBlog {
        nodes {
          uid
          url
          data {
            article_category
            author_image {
              gatsbyImageData
              url
              alt
            }
            author_name {
              richText
              text
            }
            date(formatString: "DD-MM-YYYY")
            description {
              richText
              text
            }
            display_title
            main_image {
              alt
              url
              gatsbyImageData
            }
            abstract {
              richText
              text
            }
            title {
              richText
              text
            }
          }
        }
      }
    }
  `)

  const allBlogCards = allBlogCardData.allPrismicBlog.nodes || {}

  const SelectedBlogCards = []
  for (const BlogCard of allBlogCards) {
    for (const item of otherResources) {
      if (BlogCard.url === item.resource_link.url) {
        // console.log("item.resource_link.url: ", item.resource_link.url)
        SelectedBlogCards.push(BlogCard)
      }
    }
  }
  // console.log("SelectedBlogCards: ", SelectedBlogCards)

  return (
    <div className={`OtherBlogCards`}>
      <section className="py-st-2">
        <Container className="bg-c-7 p-4">
          <Row className="justify-content-center py-3">
            <Col className="col-12">
              <h2 className="paragraph-st fs-st-15 pb-2 text-center text-828585">
                {title1}
              </h2>
              <h3 className="heading-st fs-st-4 text-center">{title2}</h3>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {SelectedBlogCards.map((item, index) => (
              <Col
                key={`blogCard-key2-${index}`}
                // On the large screen, if blog writer added more than three blog to the OtherResources,
                // it will be displayed depends on the number of blogs: (The number of blogs will be divided by 2)
                // three blogs -> three blogs in first Row
                // four blogs -> two blogs in first Row / two blogs in second Row
                // five blogs -> three blogs in first Row / two blogs in second Row
                // six blogs -> two blogs in first Row / two blogs in second Row / two blogs in third Row
                className={`g-4 col-12 col-md-6 ${
                  SelectedBlogCards.length % 2 === 0 ? "col-lg-5" : "col-lg-4"
                }`}
              >
                <PrismicLink
                  href={`/blogs/${item.uid}`}
                  className="a-st-1 rounded-st-10"
                >
                  <Card className="h-100 shadow rounded-st-10">
                    <div className="m-auto position-relative">
                      <GatsbyImage
                        className="img-wrapper-st-2 rounded-st-11-t"
                        image={item.data.main_image.gatsbyImageData}
                        alt={item.data.main_image.alt}
                      />
                      <div className="badge-st-1">
                        <Badge bg="success">{item.data.article_category}</Badge>
                      </div>
                    </div>
                    <Card.Body>
                      {/*  TODO change h3 to h1 for heading1 component */}
                      <PrismicRichText
                        field={item.data.title.richText}
                        components={{
                          heading1: ({ children }) => (
                            <h3 className="heading-st mb-3 text-center">
                              {children}
                            </h3>
                          ),
                        }}
                      />

                      <div className="paragraph-st">
                        {item.data.abstract.text.split(" ").length > 40
                          ? item.data.abstract.text
                              .split(" ")
                              .slice(0, 40)
                              .join(" ") + " ..."
                          : item.data.abstract.text}
                      </div>
                    </Card.Body>

                    <div className="d-flex flex-row justify-content-center p-2">
                      <div className="">
                        <GatsbyImage
                          className="img-wrapper-st-3 me-2"
                          image={item.data.author_image.gatsbyImageData}
                          alt={item.data.author_image.alt}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        <PrismicRichText
                          field={item.data.author_name.richText}
                          components={{
                            heading6: ({ children }) => (
                              <h6 className="heading-st">{children}</h6>
                            ),
                          }}
                        />
                        <p className="text-828585">{item.data.date}</p>
                      </div>
                    </div>
                  </Card>
                </PrismicLink>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  )
}
