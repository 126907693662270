import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const InfoWithRightImageSides = ({ slice }) => {
  // console.log("InfoWithRightImageSides Slice: ", slice)

  return (
    <div
      className={`info_with_right_image_sides style_number_${slice.primary.style_number}`}
    >
      {slice.primary.style_number === "0" ? null : (
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "bg-c-7 py-st-2"
              case "2":
                return "bg-c-7 py-st-2"
              case "3":
                return "bg-c-7 py-2"
              case "4":
                return "bg-c-7 py-st-2"
              case "5":
                return "bg-c-7 py-st-2"
              default:
                return ""
            }
          })()}
        >
          <Container
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return ""
                case "2":
                  return ""
                case "3":
                  return "bg-white rounded-20 p-3"
                case "4":
                  return ""
                case "5":
                  return ""
                default:
                  return ""
              }
            })()}
          >
            <Row className="justify-content-center">
              <Col
                style={
                  slice.primary.left_side_image.url
                    ? {
                      backgroundImage: `url(${slice.primary.left_side_image.url})`,
                    }
                    : null
                }
                className={((
                  main = `col-12 col-md-9 col-lg-6 d-flex flex-column justify-content-center`
                ) => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return `${main} bg-c-3 p-5 rounded-st-4-t rounded-st-6-l`
                    case "2":
                      return `${main} order-last order-lg-first py-2`
                    case "3":
                      return `${main} ps-lg-5`
                    case "4":
                      return `${main} bg-c-6 left-side-img-st-1 border-st-5 p-5 rounded-st-4-t rounded-st-6-l filter-st-1`
                    case "5":
                      return `${main} order-last order-lg-first py-2`
                    default:
                      return ``
                  }
                })()}
              >
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading2: ({ children }) => (
                      <h2
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "heading-st fs-st-7 text-white text-center pb-2"
                            case "2":
                              return "heading-st fs-st-7 py-3"
                            case "3":
                              return "heading-st fs-st-7 py-3"
                            case "4":
                              return "heading-st fs-st-7 text-white text-center pb-2"
                            case "5":
                              return "heading-st fs-st-7 py-3"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </h2>
                    ),
                  }}
                />

                <PrismicRichText
                  field={slice.primary.subtitle.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "paragraph-st fs-st-10 text-white text-center"
                            case "2":
                              return "paragraph-st fs-st-11 pb-3"
                            case "3":
                              return "paragraph-st fs-st-11 pb-3"
                            case "4":
                              return "paragraph-st fs-st-11 text-white text-center"
                            case "5":
                              return "paragraph-st fs-st-11 pb-3"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </p>
                    ),
                    heading2: ({ children }) => (
                      <h2
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return ""
                            case "2":
                              return ""
                            case "3":
                              return "heading-st link-in-context-st-1"
                            case "4":
                              return ""
                            case "5":
                              return ""
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </h2>
                    ),
                    list: ({ children }) => (
                      <ul
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return ""
                            case "2":
                              return "ul-st-1"
                            case "3":
                              return "ps-4"
                            case "4":
                              return ""
                            case "5":
                              return ""
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </ul>
                    ),
                    listItem: ({ children }) => (
                      <li
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return ""
                            case "2":
                              return "li-st-1 paragraph-st fs-st-11"
                            case "3":
                              return "py-1"
                            case "4":
                              return ""
                            case "5":
                              return ""
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </li>
                    ),
                  }}
                />
              </Col>
              <Col
                style={
                  slice.primary.right_side_image.url
                    ? {
                      backgroundImage: `url(${slice.primary.right_side_image.url})`,
                    }
                    : null
                }
                className={((
                  main = `col-12 col-md-9 col-lg-6 d-flex flex-column justify-content-center`
                ) => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return `${main} px-0 rounded-st-5-t rounded-st-7-r overflow-hidden`
                    case "2":
                      return `${main} m-auto px-0 order-first order-lg-last`
                    case "3":
                      return `${main} m-auto px-lg-5 text-center`
                    case "4":
                      return `${main} right-side-img-st-2 border-st-4 px-0 rounded-st-5-t rounded-st-7-r filter-st-1`
                    case "5":
                      return `${main} m-auto px-0 order-first order-lg-last`
                    default:
                      return ""
                  }
                })()}
              >
                <GatsbyImage
                  className={(() => {
                    switch (slice.primary.style_number) {
                      case "1":
                        return "w-100"
                      case "2":
                        return "clip-st-5 rounded-st-3"
                      case "3":
                        return "rounded-10 img-st-6"
                      case "4":
                        return "w-100 rounded-st-5-t rounded-st-9 bg-c-16"
                      case "5":
                        return "clip-st-5 rounded-st-3 bg-white"
                      default:
                        return ""
                    }
                  })()}
                  image={slice.primary.image.gatsbyImageData}
                  alt={slice.primary.image.alt}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithRightImageSides on PrismicPageDataBodyInfoWithRightImageSides {
    id
    slice_type
    primary {
      style_number
      image {
        gatsbyImageData(imgixParams: { auto: "", fit: "" })
        alt
        url
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
      left_side_image {
        alt
        gatsbyImageData
        url
      }
      right_side_image {
        alt
        gatsbyImageData
        url
      }
    }
  }
`
