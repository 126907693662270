const { defaultLanguage } = require("../../prismic-configuration")

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */

exports.linkResolver = doc => {
  switch (doc.type) {
    case "homepage": {
      return doc.lang === defaultLanguage ? "/" : `/${doc.lang}`
    }

    case "page": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : `/${doc.lang}/${doc.uid}`
    }

    case "blog": {
      return doc.lang === defaultLanguage
        ? `/blogs/${doc.uid}`
        : `/blogs/${doc.lang}/${doc.uid}`
    }

    default:
      return "/"
  }
}

// *****************************************
// *****************************************
// *****************************************

// console.log("-----Start---linkResolver.js-----")
// console.log("doc: ", doc)
// console.log("doc.type: ", doc.type)
// console.log("doc.data.category: ", doc.data.category)
// console.log("-----End---linkResolver.js-----")

// case "page": {
//   return doc.lang === defaultLanguage
//     ? doc.tags[0] !== undefined
//       ? `/${doc.tags[0]}/${doc.uid}`
//       : `/${doc.uid}`
//     : doc.tags[0] !== undefined
//     ? `/${doc.tags[0]}/${doc.lang}/${doc.uid}`
//     : `/${doc.lang}/${doc.uid}`
// }
