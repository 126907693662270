import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const Headline = ({ slice }) => {
  // console.log("Headline Slice: ", slice)

  return (
    <div className={`headline style_number_${slice.primary.style_number}`}>
      {slice.primary.style_number === "0" ? null : (
        <section
          className={(() => {
            switch (slice.primary.style_number) {
              case "1":
                return "bg-c-7 py-st-2"
              case "2":
                return "bg-c-7 py-st-2"
              case "3":
                return "bg-c-7 py-st-2"
              default:
                return ""
            }
          })()}
        >
          <Container>
            <Row className="justify-content-center">
              <Col
                className={(() => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return ""
                    case "2":
                      return ""
                    case "3":
                      return "col-12 col-lg-8"
                    default:
                      return ""
                  }
                })()}
              >
                <PrismicRichText
                  field={slice.primary.title.richText}
                  components={{
                    heading2: ({ children }) => (
                      <h2
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "heading-st fs-st-6 text-center py-3"
                            case "2":
                              return "heading-st fs-st-6 text-center py-3"
                            case "3":
                              return "heading-st fs-st-7 text-center py-3"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </h2>
                    ),
                  }}
                />
                <PrismicRichText
                  field={slice.primary.subtitle.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p
                        className={(() => {
                          switch (slice.primary.style_number) {
                            case "1":
                              return "paragraph-st fs-st-11 text-center"
                            case "2":
                              return "paragraph-st fs-st-11 text-center text-475763"
                            case "3":
                              return "paragraph-st fs-st-15 text-center text-828585"
                            default:
                              return ""
                          }
                        })()}
                      >
                        {children}
                      </p>
                    ),
                  }}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyHeadline on PrismicPageDataBodyHeadline {
    id
    slice_type
    primary {
      style_number
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }
    fragment HomepageDataBodyHeadline on PrismicHomepageDataBodyHeadline {
    id
    slice_type
    primary {
      style_number
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
  }
`
