import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

const Seo = ({
  companyName,
  title,
  description,
  keywords,
  pathname,
  children,
  author,
  lang,
}) => {
  const { site } = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          companyName
          title
          siteUrl
          description
          keywords {
            keyword
          }
          twitterUsername
          lang
          image
          imageAlt
        }
      }
    }
  `)

  const location = useLocation()

  function combineAndRemoveDuplicates(arr1 = [], arr2 = []) {
    const combinedArray = arr1.concat(arr2)
    const uniqueKeywords = new Set()
    const uniqueArray = combinedArray.filter(item => {
      if (!uniqueKeywords.has(item.keyword)) {
        uniqueKeywords.add(item.keyword)
        return true
      }
      return false
    })
    return uniqueArray
  }

  const seo = {
    companyName: companyName || site.siteMetadata.companyName,
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    keywords: combineAndRemoveDuplicates(keywords, site.siteMetadata.keywords),
    url: location.href,
    // url: pathname ? `${site.siteMetadata.siteUrl}${pathname}` : location.href,
    author: author,
    twitterUsername: site.siteMetadata.twitterUsername,
    lang: lang || site.siteMetadata.lang,
    image: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`,
    imageAlt: site.siteMetadata.imageAlt,
  }

  return (
    <>
      <html lang={seo.lang} />
      <title>{`${seo.title} | ${seo.companyName}`}</title>
      <meta name="description" content={seo.description} />
      <meta
        name="keywords"
        content={seo.keywords
          .map(keyword => {
            return keyword.keyword + ", "
          })
          .join("")
          .slice(0, -2)}
      ></meta>
      <link rel="canonical" href={seo.url} />
      <meta name="image" content={seo.image} />
      <meta name="author" content={seo.author} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.imageAlt} />
      <meta property="og:site_name" content={seo.companyName} />
      <meta property="og:locale" content={seo.lang} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.imageAlt} />
      <meta name="twitter:site" content={seo.twitterUsername} />

      {children}
    </>
  )
}

export default Seo
