import * as React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Card } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"

export const EmbeddedFormWithInfo = ({ slice }) => {
  // console.log("EmbeddedFormWithInfo Slice: ", slice)

  React.useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src = slice.primary.form_link.url
    script.id = slice.primary.form_id
    script.crossorigin = "anonymous"

    const form = document.getElementById("form")
    form.title = slice.primary.form_title
    form.appendChild(script)

    return () => {
      form.removeChild(script)
    }
  }, [
    slice.primary.form_title,
    slice.primary.form_link.url,
    slice.primary.form_id,
  ])

  return (
    <div className={`embedded_form_with_info`}>
      {/* embedded form with info */}
      <section className="bg-c-7 py-st-2">
        <Container>
          <Row className="mb-3 mb-md-4">
            <Col
              xs={{ span: 12, order: "last" }}
              lg={{ span: 6, order: "first" }}
            >
              <div className="" id="form">
                {/* Used React.useEffect() to render this form  */}
              </div>
            </Col>
            <Col
              xs={{ span: 12, order: "first" }}
              lg={{ span: 6, order: "last" }}
            >
              <Row>
                <Col className="mb-3 mb-md-4">
                  <PrismicRichText
                    field={slice.primary.main_title.richText}
                    components={{
                      heading2: ({ children }) => (
                        <h2 className="heading-st fs-st-3 mb-3">{children}</h2>
                      ),
                    }}
                  />
                  <PrismicRichText
                    field={slice.primary.main_subtitle.richText}
                    components={{
                      paragraph: ({ children }) => (
                        <p className="paragraph-st fs-st-17">{children}</p>
                      ),
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center d-none d-lg-flex">
                {slice.items.map((item, index) => (
                  <Col key={`steps-with-cards-${index}`} xs={{ span: 6 }}>
                    <Card className="bg-c-7 border-0 shadow-none h-100 py-3 py-lg-0">
                      <div className="m-auto mt-lg-3">
                        <GatsbyImage
                          className="img-st-18 filter-st-1"
                          image={item.image.gatsbyImageData}
                          alt={item.image.alt}
                        />
                      </div>
                      <Card.Body className="">
                        <PrismicRichText
                          field={item.title.richText}
                          components={{
                            heading3: ({ children }) => (
                              <h3 className="card-st-2-title fs-st-15 my-1 my-md-2 my-lg-3">
                                {children}
                              </h3>
                            ),
                          }}
                        />
                        <PrismicRichText
                          field={item.description.richText}
                          components={{
                            paragraph: ({ children }) => (
                              <p className="card-st-3-text">{children}</p>
                            ),
                          }}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyEmbeddedFormWithInfo on PrismicPageDataBodyEmbeddedFormWithInfo {
    id
    slice_type
    primary {
      form_title
      form_link {
        url
      }
      main_title {
        richText
        text
      }
      main_subtitle {
        richText
        text
      }
    }
    items {
      image {
        alt
        gatsbyImageData
        url
      }
      title {
        richText
        text
      }
      description {
        richText
        text
      }
    }
  }
`
