import React from "react"
import { Accordion, Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { idMaker } from "../utils/idMaker"

export const FAQ = ({ slice }) => {
  // console.log("FAQ Slice: ", slice)

  return (
    <div className={`faq`} id={idMaker(slice.primary.title.text)}>
      <section className="bg-c-7 pb-3 pb-lg-5">
        <Container>
          <Row>
            <Col className="col-lg-10 col-12 offset-lg-1 offset-0">
              <h3 className="heading-st fs-st-6 w-100 text-center py-4 py-lg-5">
                {slice.primary.title.text}
              </h3>
              <div
                className="accordion md-accordion bg-white rounded-10 overflow-hidden"
                id="accordionEx"
                role="tablist"
                aria-multiselectable="true"
              >
                <Accordion
                  alwaysOpen
                  className="frequently-asked-questions-accordion"
                >
                  {slice.items.map((item, index) => (
                    <Accordion.Item
                      key={`frequently-asked-questions-${index}`}
                      eventKey={index}
                      className="frequently-asked-questions-accordion-item"
                    >
                      <Accordion.Header className="frequently-asked-questions-accordion-header">
                        {item.question}
                      </Accordion.Header>
                      <Accordion.Body>
                        <PrismicRichText
                          field={item.answer.richText}
                          components={{
                            paragraph: ({ children }) => (
                              <p className="frequently-asked-questions-accordion-body p-1">
                                {children}
                              </p>
                            ),
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFaq on PrismicHomepageDataBodyFaq {
    id
    slice_type
    primary {
      title {
        richText
        text
      }
    }
    items {
      question
      answer {
        richText
        text
      }
    }
  }

  fragment PageDataBodyFaq on PrismicPageDataBodyFaq {
    id
    slice_type
    primary {
      title {
        richText
        text
      }
    }
    items {
      question
      answer {
        richText
        text
      }
    }
  }
`
