import React from "react"
import { Link, graphql } from "gatsby"
import { PrismicLink } from "@prismicio/react"
import {
  Navbar as ReactBootstrapNavbar,
  Nav,
  Image,
  Container,
  Col,
} from "react-bootstrap"
import CustomDropdown from "./CustomDropdown"

export const Navbar = ({ navbar }) => {
  const subCategories = navbar.body.filter(
    slice => slice.slice_type === "subcategories"
  )

  return (
    <header className="main-navbar position-relative">
      <ReactBootstrapNavbar expand="lg" className="navbar" fixed="top">
        <Container>
          <ReactBootstrapNavbar.Brand>
            <Link
              className="d-flex justify-content-center align-items-center"
              to="/"
            >
              <Image
                alt="logo"
                src="/logo.png"
                className="d-inline-block align-top img-st-1 me-1"
              />{" "}
              <div className="ms-2">
                <div className="logo">GRUB24</div>
                <div className="logo">For Restaurants</div>
              </div>
            </Link>
          </ReactBootstrapNavbar.Brand>
          <ReactBootstrapNavbar.Toggle aria-controls="navbarScroll" />
          <ReactBootstrapNavbar.Collapse id="navbarScroll">
            <Nav
              className="w-100 d-flex flex-column flex-lg-row justify-content-between"
              navbarScroll
            >
              {/* This is the first group of links */}
              <div className="nav-group-1 order-1 order-lg-2 d-flex flex-row justify-content-center">
                <div className="me-1 me-md-3 mt-4 mb-3 mx-lg-2 my-lg-3">
                  <a
                    className="btn-login"
                    href="https://grub24.co.uk/merchant/login/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Log in
                  </a>
                </div>
                <div className="mt-4 mb-3 mx-lg-2 my-lg-3 position-relative">
                  <Link className="btn-signup" to="/talk-to-sales">
                    Talk to sales
                  </Link>
                  <div className="spinner-st-1"></div>
                </div>
              </div>

              {/* This is the second group of links */}
              <div className="nav-group-2 order-2 order-lg-1 d-lg-flex flex-row justify-content-center ms-lg-4">
                <hr className="mt-2 mb-3 d-lg-none" />

                {navbar &&
                  navbar.main_categories.map((category, index) => {
                    const subCategoriesOfEachMainCategory = subCategories.filter(
                      subCategory =>
                        category.category_number ===
                        subCategory.primary.category_number
                    )

                    return (
                      <React.Fragment key={`category-item-${index}`}>
                        {index !== 0 && <hr className="divider-st-2" />}

                        {subCategoriesOfEachMainCategory.length !== 0 ? (
                          <CustomDropdown
                            toggleContext={category.title}
                            menuContext={subCategoriesOfEachMainCategory.map(
                              (subCategory, index) => {
                                return (
                                  <Col
                                    key={`subCategory-item-${index}`}
                                    className={`py-3 py-lg-5 col-12 col-md-6 col-lg-3 ${index === 0 &&
                                      subCategoriesOfEachMainCategory.length <=
                                      2
                                      ? "offset-lg-2"
                                      : ""
                                      }${index === 0 &&
                                        subCategoriesOfEachMainCategory.length ===
                                        3
                                        ? "offset-lg-1"
                                        : ""
                                      }`}
                                  >
                                    {subCategory.primary.title && (
                                      <>
                                        <div className="px-2 pb-2 fw-bold text-center text-lg-start">
                                          {subCategory.primary.title}
                                        </div>
                                        <div className="border-bottom mx-2 mb-2"></div>
                                      </>
                                    )}
                                    {subCategory.primary.subtitle && (
                                      <div className="px-2 py-1 fw-bold">
                                        {subCategory.primary.subtitle}
                                      </div>
                                    )}
                                    {subCategory.items.map((item, index) => (
                                      <div key={`subCategory-links-${index}`}>
                                        <div>
                                          <PrismicLink
                                            field={item.link}
                                            className="dropdown-item d-flex justify-content-left justify-content-lg-start align-items-center"
                                          >
                                            <Image
                                              className="me-2"
                                              width={16}
                                              height={16}
                                              src={item.icon.url}
                                              alt={item.icon.alt}
                                            />
                                            {item.label}
                                          </PrismicLink>
                                        </div>
                                        <hr className="divider-st-2" />
                                      </div>
                                    ))}
                                  </Col>
                                )
                              }
                            )}
                          />
                        ) : (
                          <PrismicLink
                            field={category.link}
                            className={`nav-link-st-1 ${category.right_align ? "ms-auto" : ""
                              }`}  // Conditionally apply ms-auto
                          >
                            {category.title}
                          </PrismicLink>
                        )}
                      </React.Fragment>
                    )
                  })}
              </div>
            </Nav>
          </ReactBootstrapNavbar.Collapse>
        </Container>
      </ReactBootstrapNavbar>
    </header>
  )
}

export const query = graphql`
  fragment NavbarFragment on PrismicNavbar {
    _previewable
    type
    lang
    data {
      main_categories {
        category_number
        title
        right_align  # Ensure this field is fetched
        link {
          url
          target
          link_type
        }
      }
      body {
        ... on PrismicNavbarDataBodySubcategories {
          id
          slice_type
          items {
            icon {
              alt
              url
            }
            label
            link {
              url
              target
              link_type
            }
          }
          primary {
            category_number
            subtitle
            title
          }
        }
      }
    }
  }
`
