import React from "react"
import { graphql } from "gatsby"
// import { Container, Row, Col } from "react-bootstrap"
// import { PrismicRichText } from "@prismicio/react"

export const BackgroundVideo = ({ slice }) => {
  // console.log("BackgroundVideo Slice: ", slice)

  return (
    <div className={`background_video`}>
      <section className="d-flex justify-content-center align-items-center bg-c-7 py-st-2">
        {/* eslint-disable jsx-a11y/media-has-caption */}
        <video
          className="video-st-1"
          autoPlay
          loop
          muted="muted"
          playsInline
          controlsList="nodownload"
        >
          <source src={`${slice.primary.media.url}`} type="video/mp4" />
        </video>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyBackgroundVideo on PrismicPageDataBodyBackgroundVideo {
    id
    slice_type
    primary {
      media {
        url
      }
    }
  }
`
