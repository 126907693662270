import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "@prismicio/react"

export const InfoWithFullWidthImage = ({ slice }) => {
  // console.log("InfoWithFullWidthImage Slice: ", slice)

  return (
    <div
      className={`info_with_full_width_image style_number_${slice.primary.style_number}`}
    >
      <section
        className={(() => {
          switch (slice.primary.style_number) {
            case "1":
              return "bg-c-7 py-st-2"
            case "2":
              return "bg-c-7 py-st-2"
            default:
              return ""
          }
        })()}
      >
        <Container>
          <Row
            className={(() => {
              switch (slice.primary.style_number) {
                case "1":
                  return "mb-3 mb-md-4"
                case "2":
                  return "mb-3 mb-md-4"
                default:
                  return ""
              }
            })()}
          >
            <Col>
              <PrismicRichText
                field={slice.primary.title.richText}
                components={{
                  heading2: ({ children }) => (
                    <h2
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "heading-st fs-st-3 mb-3 text-center"
                          case "2":
                            return "heading-st fs-st-5 mb-3 text-center"
                          default:
                            return ""
                        }
                      })()}
                    >
                      {children}
                    </h2>
                  ),
                }}
              />
              <PrismicRichText
                field={slice.primary.subtitle.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "paragraph-st fs-st-11 text-center"
                          case "2":
                            return "paragraph-st fs-st-11 text-center"
                          default:
                            return ""
                        }
                      })()}
                    >
                      {children}
                    </p>
                  ),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              className={(() => {
                switch (slice.primary.style_number) {
                  case "1":
                    return "text-center"
                  case "2":
                    return "text-center"
                  default:
                    return ""
                }
              })()}
            >
              <GatsbyImage
                className={(() => {
                  switch (slice.primary.style_number) {
                    case "1":
                      return "filter-st-1"
                    case "2":
                      return "rounded-10 filter-st-1"
                    default:
                      return ""
                  }
                })()}
                image={slice.primary.image.gatsbyImageData}
                alt={slice.primary.image.alt}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithFullWidthImage on PrismicPageDataBodyInfoWithFullWidthImage {
    id
    slice_type
    primary {
      style_number
      image {
        alt
        gatsbyImageData
        url
      }
      subtitle {
        text
        richText
      }
      title {
        text
        richText
      }
    }
  }
`
