import * as React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { SliceZone } from "@prismicio/react"

import Layout from "../components/Layout"
import { components } from "../slices"
import Seo from "../components/Seo"
// import { useScrollRestoration } from "gatsby"

const PageTemplate = ({ data }) => {
  // const scrollRestoration = useScrollRestoration(`page-template-scroll`)
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}
  const footer = data.prismicFooter || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const navbar = data.prismicNavbar || {}

  return (
    <Layout navbar={navbar.data} activeDocMeta={activeDoc} footer={footer.data}>
      <SliceZone
        slices={page.body}
        components={components}
      // {...scrollRestoration}
      />
    </Layout>
  )
}

export const Head = ({ data }) => {
  return (
    <Seo
      title={data.prismicPage.data.meta_title}
      description={data.prismicPage.data.meta_description}
      keywords={data.prismicPage.data.meta_keywords}
    />
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      tags
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        display_title
        meta_title
        meta_description
        meta_keywords {
          keyword
        }
        body {
          ... on PrismicSlice {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyArticleWithImage
          ...PageDataBodyBackgroundVideo
          ...PageDataBodyBanner
          ...PageDataBodyCtaWithCardsDoubleLinks
          ...PageDataBodyCtaWithCardsImages
          ...PageDataBodyCtaWithFullWidthImage
          ...PageDataBodyCtaWithLeftImage
          ...PageDataBodyCtaWithRightImage
          ...PageDataBodyCtaWithRightImageLinks
          ...PageDataBodyDemoWithTabsImages
          ...PageDataBodyEmbeddedFormWithInfo
          ...PageDataBodyFaq
          ...PageDataBodyHero
          ...PageDataBodyTwoColumnContent
          ...PageDataBodyFeaturesWithCardsImages
          ...PageDataBodyHeadline
          ...PageDataBodyInfoWithBackgroundImage
          ...PageDataBodyInfoWithFullWidthImage
          ...PageDataBodyInfoWithFullWidthVideo
          ...PageDataBodyInfoWithLeftImage
          ...PageDataBodyInfoWithRightImage
          ...PageDataBodyInfoWithRightImageItems
          ...PageDataBodyInfoWithRightImageSides
          ...PageDataBodyJoinUs
          ...PageDataBodyPartnersWithCardsImages
          ...PageDataBodyPosts
          ...PageDataBodyRedirectWithCardsImages
          ...PageDataBodyDownloadsWithCardsImages
          ...PageDataBodyStatistics
          ...PageDataBodyStepsWithCardsImages
          ...PageDataBodyTestimonials
          ...PageDataBodyTestSlice
          ...PageDataBodyWrittenByWithImage
          ...PageDataBodyPricing
        }
      }
    }
    prismicNavbar(lang: { eq: $lang }) {
      ...NavbarFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(PageTemplate)
