import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap"

export const DemoWithTabsImages = ({ slice }) => {
  // console.log("DemoWithTabsImages Slice: ", slice)

  return (
    <div
      className={`demo_with_tabs_images style_number_${slice.primary.style_number}`}
    >
      <section className="bg-c-7 py-st-2">
        <Container className="border rounded-3 bg-white shadow">
          <Row className="justify-content-center align-items-center">
            <Col
              className={(() => {
                switch (slice.primary.style_number) {
                  case "1":
                    return "col-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center align-items-center pb-2 px-0 pb-lg-0"
                  case "2":
                    return "d-none"
                  default:
                    return ""
                }
              })()}
            >
              {slice.primary.image.gatsbyImageData ? (
                <GatsbyImage
                  className="filter-st-1"
                  image={slice.primary.image.gatsbyImageData}
                  alt={slice.primary.image.alt}
                />
              ) : null}
            </Col>
            <Col
              className={(() => {
                switch (slice.primary.style_number) {
                  case "1":
                    return "col-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center align-items-center pe-lg-5"
                  case "2":
                    return "d-flex flex-column justify-content-center align-items-center"
                  default:
                    return ""
                }
              })()}
            >
              <PrismicRichText
                field={slice.primary.title.richText}
                components={{
                  heading1: ({ children }) => <h1 className="">{children}</h1>,
                  heading2: ({ children }) => (
                    <h2
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "heading-st fs-st-6 py-3 text-center"
                          case "2":
                            return "heading-st fs-st-6 py-3 text-center"
                          default:
                            return ""
                        }
                      })()}
                    >
                      {children}
                    </h2>
                  ),
                  heading3: ({ children }) => (
                    <h3
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "heading-st fs-st-6 pt-4 pt-md-4 pb-md-2 pt-lg-5 text-center"
                          case "2":
                            return "heading-st fs-st-6 pt-4 pt-md-4 pb-md-2 pt-lg-5 text-center"
                          default:
                            return ""
                        }
                      })()}
                    >
                      {children}
                    </h3>
                  ),
                }}
              />
              <PrismicRichText
                field={slice.primary.subtitle.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p
                      className={(() => {
                        switch (slice.primary.style_number) {
                          case "1":
                            return "paragraph-st fs-st-11 pt-3 pb-4 px-xl-5 text-center"
                          case "2":
                            return "paragraph-st fs-st-11 pt-3 pb-4 px-xl-5 text-center"
                          default:
                            return ""
                        }
                      })()}
                    >
                      {children}
                    </p>
                  ),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="px-0">
              <Tabs defaultActiveKey="tab-0" className="nav-tabs-st-1" justify>
                {slice.items.map((item, index) => (
                  <Tab
                    key={`demo_with_tabs_images-tab-${index}`}
                    eventKey={`tab-${index}`}
                    title={item.title.text}
                    className="h-100"
                  >
                    <Container>
                      <Row className="justify-content-center py-3 py-md-4">
                        <Col className="col-12 col-md-10 col-lg-6 m-auto px-1 px-md-5 px-lg-5">
                          <GatsbyImage
                            className="filter-st-1"
                            image={item.image.gatsbyImageData}
                            alt={item.image.alt}
                          />
                        </Col>
                        <Col className="col-12 col-md-10 col-lg-6 m-auto px-3 px-md-5 px-lg-4">
                          <PrismicRichText
                            field={item.description.richText}
                            components={{
                              heading6: ({ children }) => (
                                <h6 className="heading-st fs-st-9 py-2 px-md-3 px-lg-0">
                                  {children}
                                </h6>
                              ),
                              paragraph: ({ children }) => (
                                <p className="paragraph-st mt-1 px-md-3 px-lg-0">
                                  {children}
                                </p>
                              ),
                            }}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                ))}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyDemoWithTabsImages on PrismicPageDataBodyDemoWithTabsImages {
    id
    slice_type
    primary {
      style_number
      image {
        alt
        url
        gatsbyImageData
      }
      subtitle {
        richText
        text
      }
      title {
        richText
        text
      }
    }
    items {
      description {
        richText
        text
      }
      title {
        richText
        text
      }
      image {
        alt
        gatsbyImageData
        url
      }
    }
  }
`
