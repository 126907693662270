import * as React from "react"
import { Link } from "gatsby"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { PrismicProvider } from "@prismicio/react"

import { repositoryConfigs } from "./src/utils/prismicPreviews"
import { linkResolver } from "./src/utils/linkResolver"

// import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
  // console.log("-----Start---gatsby-browser.js-----")
  // console.log("linkResolver: ", linkResolver("test"))
  // console.log("-----End---gatsby-browser.js-----")

  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {element}
      </PrismicPreviewProvider>
    </PrismicProvider>
  )
}
