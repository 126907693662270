import React from "react"
import { graphql } from "gatsby"
import { idMaker } from "../utils/idMaker"
import { Container, Row, Col } from "react-bootstrap"

export const Services = ({ slice }) => {
  // console.log("Services Slice: ", slice)

  return (
    <div className={`services`} id={idMaker(slice.primary.title.text)}>
      <div className="bg-c-7 py-st-2">
        <Container>
          <h3 className="heading-st fs-st-6 pb-lg-4 text-center mb-3 mb-md-4">
            {slice.primary.title.text}
          </h3>
          <Row xs={1} md={2} lg={3} className="px-3">
            {slice.items.map((item, index) => (
              <Col key={`services-col-${index}`} className="pb-3">
                <span className="paragraph-st fs-st-18 text-grub-blue">
                  {item.service_number.text}{" "}
                </span>
                <span className="paragraph-st fs-st-18">
                  {item.service_title}
                </span>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyServices on PrismicHomepageDataBodyServices {
    id
    slice_type
    primary {
      title {
        text
        richText
      }
    }
    items {
      service_title
      service_number {
        text
        richText
      }
    }
  }
`
